import { Box, Grid, Link, useMediaQuery, useTheme } from "@material-ui/core";
import { PRIVACY_URL } from "../../routing/externalUrls";

export const LegalNoticePage: React.FC = () => {
  const theme = useTheme();
  const extraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <Grid container spacing={4} style={{ marginBottom: extraSmall ? "0px" : "20px" }}>
        <Grid item sm={7}>
          <h1>Impressum</h1>
          <div>
            Unsere <Link href={PRIVACY_URL}>Datenschutzerklärung</Link> finden Sie{" "}
            <Link href={PRIVACY_URL}>hier</Link>.
          </div>
        </Grid>
      </Grid>

      <Grid>
        <Box component={"div"} className={"h2"}>
          <h2>Angaben gemäß § 5 TMG</h2>
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          <p>
            amanzii GmbH <br />
            Frankfurter Straße 1A <br />
            61231 Bad Nauheim
          </p>
          <p>
            Amtsgericht Friedberg <br />
            HRB 9253
          </p>
          <p>USt-IdNr. DE328806773</p>
          <p>
            Vertreten durch den Geschäftsführer: <br />
            Oliver Kuklok
          </p>
        </Box>
      </Grid>
      <Grid>
        <Box component={"div"} className={"h2"}>
          <h2>Kontakt</h2>
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          <p>Telefon: +49 6034 939 1334</p>
          <p>E-Mail: info@amanzii.de</p>
        </Box>
      </Grid>
      <Grid>
        <Box component={"div"} className={"h2"}>
          <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          <p>
            Oliver Kuklok <br />
            amanzii GmbH <br />
            Frankfurter Straße 1A <br />
            61231 Bad Nauheim <br />
          </p>
        </Box>
      </Grid>
      <Grid>
        <Box component={"div"} className={"h2"}>
          <h2>Streitschlichtung</h2>
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
            (OS) bereit:{" "}
            <Link href={"https://ec.europa.eu/consumers/odr"}>
              https://ec.europa.eu/consumers/odr
            </Link>
            .
          </p>
          <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
          <p>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
            einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>
        </Box>
      </Grid>
      <Grid>
        <Box component={"div"} className={"h2"}>
          <h2>Haftung für Inhalte</h2>
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
            diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10
            TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
            forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          </p>
          <p>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
            nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
            Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
        </Box>
      </Grid>
      <Grid>
        <Box component={"div"} className={"h2"}>
          <h2>Haftung für Links</h2>
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          <p>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte
            wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
            keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
            jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
            Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
            überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p>
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
            konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
            Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
            entfernen.
          </p>
        </Box>
      </Grid>
      <Grid>
        <Box component={"div"} className={"h2"}>
          <h2>Urheberrecht</h2>
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
            unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
            Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
            Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
            bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
            nicht kommerziellen Gebrauch gestattet.
          </p>
          <p>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
            werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
            Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
            Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
            Inhalte umgehend entfernen.
          </p>
        </Box>
      </Grid>
    </>
  );
};

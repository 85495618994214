import { Link } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  LumosDateSvg,
  LumosEducationSvg,
} from "../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";
import { MyEventListData } from "../../../../models/eventData";
import { clearBookings } from "../../../../redux/bookings/actions";
import routes from "../../../../routing/routes";
import styles from "../../../../styles/custom-styles.module.scss";
import { useState } from "react";
import { AppState } from "../../../../redux";
import { LecturesData } from "../../../../models/lecturesData";
import { MyELearningData } from "../../../../models/elearning";
import { clearLecture } from "../../../../redux/lectures/actions";
import { clearChecklist } from "../../../../redux/checklist/actions";
import { clearDocuments } from "../../../../redux/documents/actions";
import { clearParticipant } from "../../../../redux/participants/actions";
import { defaultPalette } from "../../../theming/defaultTheme";

/**
 * 2 events the training-type blended-learning is composed of
 */
export const SUB_EVENTS: string[] = [
  mapToTrainingTypeString(TrainingType.DefaultEvent),
  mapToTrainingTypeString(TrainingType.ELearning),
];

interface MyEventTitleContentProps {
  event: MyEventListData | LecturesData;
  text: string;
}

const MyEventTitleContent: React.FC<MyEventTitleContentProps> = ({ event, text }) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const isSpeakerView = (event as LecturesData).speaking_event !== undefined,
    eventData = isSpeakerView
      ? (event as LecturesData)
      : ((event as MyEventListData).event as MyELearningData),
    bookingId = isSpeakerView ? (eventData as LecturesData).participation_id : event.id,
    isLinkToDetails =
      eventData.training_type !== TrainingType.BlendedLearning ||
      SUB_EVENTS.includes(text),
    isELearning =
      eventData.training_type === TrainingType.ELearning || text === SUB_EVENTS[1];

  const openDetails = () => {
    dispatch(clearBookings());
    if (isSpeakerView) {
      dispatch(clearDocuments());
      dispatch(clearParticipant());
      dispatch(clearLecture());
      dispatch(clearChecklist());
    }
  };

  const eventIDLink = `${isELearning && !isSpeakerView ? "/" + bookingId : ""}`;

  return (
    <Link
      component={RouterLink}
      to={`${
        isELearning
          ? isSpeakerView
            ? routes.my_lectures_details_elearning
            : routes.my_events_details_elearning
          : isSpeakerView
            ? routes.my_lectures_details
            : routes.my_events_details
      }/${
        isSpeakerView ? (isELearning ? bookingId : eventData.id) : bookingId
      }${eventIDLink}`}
      onClick={() => {
        isLinkToDetails && openDetails();
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      style={{
        fontWeight: SUB_EVENTS.includes(text) ? "normal" : "bold",
        cursor: isLinkToDetails ? "pointer" : "default",
        color:
          !isLinkToDetails || accessibility.monoMode
            ? "#000"
            : isHovered
              ? defaultPalette.primary?.main
              : styles.berry,
        borderBottomWidth: isLinkToDetails ? "2px" : "0",
      }}
    >
      {text === SUB_EVENTS[0] && <LumosDateSvg />}
      {text === SUB_EVENTS[1] && <LumosEducationSvg />}
      {text}
      <br />
    </Link>
  );
};

export default MyEventTitleContent;

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import { FieldAttributes, useField } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { GroupedSelectData, SelectData } from "./selectData";

interface CustomMultiSelectProps {
  id: string;
  labelId: string;
  label: string;
  defaultOptions: string[] | number[];
  options: SelectData[] | GroupedSelectData[];
  defaultText?: string; // used for empty option
  isGrouped?: boolean;
  hasCustomChangeHandler?: boolean;
  customChangeHandler?: (values: string[] | number[]) => void;
}

const CustomMultiSelect: React.FunctionComponent<
  FieldAttributes<{}> & CustomMultiSelectProps & SelectProps
> = ({
  defaultText,
  defaultOptions,
  hasCustomChangeHandler,
  customChangeHandler,
  labelId,
  label,
  options,
  isGrouped,
  multiple,
  required,
  ...props
}) => {
  const [field, meta] = useField<{}>(props);

  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text

  const [optionName, setOptionName] = useState<string[] | number[]>(defaultOptions);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (hasCustomChangeHandler && customChangeHandler)
      customChangeHandler(event.target.value as string[]);
    setOptionName(event.target.value as string[]);
  };

  useEffect(() => {
    setOptionName(defaultOptions);
  }, [defaultOptions]);

  return (
    <>
      <FormControl fullWidth variant="filled" error={!!error}>
        <InputLabel required={required} id={labelId}>
          {label}
        </InputLabel>
        <Select
          {...field}
          {...props}
          multiple
          value={optionName}
          onChange={handleChange}
          MenuProps={{
            disableAutoFocus: true,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
        >
          {isGrouped
            ? options &&
              (options as GroupedSelectData[]).map(
                (group: GroupedSelectData, index: number) => {
                  return [
                    isGrouped &&
                      group.groupCategoryHeader !== "" &&
                      group.options.length > 0 && (
                        <ListSubheader
                          key={`sub-header-${group.groupCategoryHeader}`}
                          disableGutters={false}
                          disableSticky={true}
                          color="default"
                          inset={true}
                          style={{
                            width: "75%",
                            borderBottom: "1px solid var(--secondary-accent-1)",
                            lineHeight: "0.1em",
                            margin: "10px 1rem 20px",
                          }}
                        >
                          <Box
                            component="span"
                            style={{
                              background: "#fff",
                              padding: "0 10px",
                            }}
                          >
                            {group.groupCategoryHeader}
                          </Box>
                        </ListSubheader>
                      ),
                    group.options.map((option: SelectData, j: number) => (
                      <MenuItem key={option.key} value={option.value}>
                        {option.label ? option.label : option.value}
                      </MenuItem>
                    )),
                  ];
                }
              )
            : options &&
              (options as SelectData[]).map((option: SelectData, j: number) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.label ? option.label : option.value}
                </MenuItem>
              ))}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </>
  );
};

export default CustomMultiSelect;

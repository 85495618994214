import * as React from "react";
import { Button, Grid, Link, Switch, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Alert } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setPaymentMethod } from "../../../redux/billingAddress/actions";
import { TERMS_AND_CONDITIONS_URL } from "../../../routing/externalUrls";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import CartList from "../../../components/core/booking/CartList";
import "./CheckoutPage.scss";
import { PaymentOptionDropDown } from "../../../components/core/booking/checkout/dropDownField/PaymentOptionDropDown";
import { AppState } from "../../../redux";
import { useTranslation } from "react-i18next";
import { setOrderData } from "../../../redux/orders/actions";
import { orderService } from "../../../api";
import { useEffect, useState } from "react";
import {
  fetchMemberships,
  setMembershipsLoaded,
} from "../../../redux/organizationMembership/action";
import Loader from "../../../components/theming/loader/Loader";
import { PAYMENT_METHOD_GIROPAY_ID } from "../../../constants";
import {
  getPaymentMethods,
  setPaymentMethodsLoaded,
} from "../../../redux/paymentMethod/actions";
import { Utils } from "../../../services/utils";

type CheckoutFormDataStepPayment = { payment_method: string };

export const CheckoutPage: React.FC = () => {
  const { t } = useTranslation(["booking", "common", "layout", "snackbars"]);
  const history = useHistory();
  const dispatch = useDispatch();

  const cartDataState = useSelector((appState: AppState) => appState.cart);
  const organizationMemberships = useSelector(
    (appState: AppState) => appState.memberships
  );
  const paymentMethodState = useSelector(
    (appState: AppState) => appState.paymentMethods
  );

  const [ATBandPrivacy, setATBandPrivacy] = useState(false);
  const [orderIsFreeOfCharge, setOrderIsFreeOfCharge] = useState(false);
  const [userHasNoOrganizations, setUserHasNoOrganizations] = useState(false);
  const [grossPrice, setGrossPrice] = useState(0);

  useEffect(() => {
    if (!paymentMethodState.isLoading && !paymentMethodState.paymentMethodsLoaded) {
      dispatch(getPaymentMethods());
      dispatch(setPaymentMethodsLoaded());
    }
  }, [
    dispatch,
    paymentMethodState,
    paymentMethodState.paymentMethodsLoaded,
    paymentMethodState.paymentMethods,
  ]);

  useEffect(() => {
    if (
      !organizationMemberships.isLoading &&
      !organizationMemberships.memberShipsLoaded
    ) {
      dispatch(fetchMemberships());
      dispatch(setMembershipsLoaded());
    }
    setUserHasNoOrganizations(organizationMemberships.memberships.length === 0);
  }, [dispatch, organizationMemberships]);

  useEffect(() => {
    if (!cartDataState.isLoading && cartDataState.cartInitialLoaded) {
      setOrderIsFreeOfCharge(cartDataState.cart.gross_total.toString() === "0.0000");
      setGrossPrice(cartDataState.cart.gross_total / 100);
    }
  }, [cartDataState]);

  let giropayIsAvailable = false;
  if (paymentMethodState.paymentMethodsLoaded) {
    let giropay = null;
    giropay = Utils.getPaymentMethod(
      paymentMethodState.paymentMethods,
      PAYMENT_METHOD_GIROPAY_ID
    );
    giropayIsAvailable = giropay ? giropay.is_available : false;
  }

  const formikConf = {
    onSubmit: (data: CheckoutFormDataStepPayment) => {
      if (data.payment_method === "Rechnung") {
        history.push(routes.bill);
        dispatch(setPaymentMethod(data.payment_method));
      }
      if (data.payment_method === "PayPal") {
        orderService.createOrder(cartDataState.cart.cart_items).then((order) => {
          dispatch(setOrderData(order));
          dispatch(setPaymentMethod(data.payment_method));
          history.push(routes.paypal);
        });
      }
    },
    enableReinitialize: true,
    initialValues: {
      payment_method: "",
    } as CheckoutFormDataStepPayment,
  };

  if (
    organizationMemberships.isLoading ||
    !organizationMemberships.memberShipsLoaded ||
    cartDataState.isLoading ||
    paymentMethodState.isLoading ||
    !paymentMethodState.paymentMethodsLoaded
  ) {
    return <Loader />;
  }
  return (
    <>
      <HeadingLumos>{t("afterCheckout.header", { ns: "booking" })}</HeadingLumos>
      <Formik {...formikConf}>
        {({ values, handleChange }) => {
          const canProceed =
            values.payment_method !== "" &&
            ATBandPrivacy &&
            cartDataState.cart.cart_items.length !== 0;

          return (
            <Form>
              <Grid container spacing={0}>
                <Typography>{t("checkout.note", { ns: "booking" })}</Typography>
                <Grid item xs={12}>
                  <h3>{t("checkout.header", { ns: "booking" })}</h3>
                </Grid>
              </Grid>
              <CartList checkout={true} />
              <PaymentOptionDropDown
                value={values.payment_method}
                onChange={handleChange}
                orderIsFreeOfCharge={orderIsFreeOfCharge}
                userHasNoOrganizations={userHasNoOrganizations}
                grossPrice={grossPrice}
                giropayIsAvailable={giropayIsAvailable}
              />
              <Box component="div" className={"checkout-atb-box"}>
                {t("checkout.AGBPrivacyAccepted.part1", { ns: "booking" })}{" "}
                <Link
                  href={TERMS_AND_CONDITIONS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("footer.agb", { ns: "layout" })}
                </Link>{" "}
                {t("checkout.AGBPrivacyAccepted.part2", { ns: "booking" })}{" "}
                <Link
                  href={routes.privacy_policy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("privacyDec", { ns: "common" })}
                </Link>{" "}
                {t("checkout.AGBPrivacyAccepted.part3", { ns: "booking" })}{" "}
                <Switch
                  // checked={ATBandPrivacy}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setATBandPrivacy(event.target.checked);
                  }}
                  color="primary"
                  name="ATBandPrivacyCheck"
                  inputProps={{ "aria-label": "ATB and Privacy checkbox" }}
                />
              </Box>
              {!ATBandPrivacy && (
                <Box my={2}>
                  <Alert severity="error">
                    {t("checkout.AGBPrivacyAccept", { ns: "booking" })}
                  </Alert>
                </Box>
              )}
              <Grid item xs={12}>
                <Box
                  component={"div"}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  className={"shopping-cart-bookingbutton-container"}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      id="back-to-home"
                      size="medium"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        history.push(routes.home);
                      }}
                      style={{ width: "20%" }}
                    >
                      {t("backToHome", { ns: "booking" })}
                    </Button>
                    <Button
                      id="checkout-to-payment"
                      size="medium"
                      variant="contained"
                      color="primary"
                      type={"submit"}
                      disabled={!canProceed}
                      style={{ width: "20%" }}
                    >
                      {t("checkout.toPayment", { ns: "booking" })}
                    </Button>
                  </div>
                </Box>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

import { Box, Divider, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import "../../../components/core/events/list/info/LearningEventsInfo.scss";

export const LearningEventsOverviewPage: React.FC = () => {
  const theme = useTheme();
  const extraSmall = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Grid container spacing={4} style={{ marginBottom: extraSmall ? "0px" : "20px" }}>
        <Grid item sm={7}>
          <HeadingLumos>Lernangebote im Überblick</HeadingLumos>
        </Grid>
      </Grid>

      <Grid>
        <Box component={"div"} className={"learning-events-overview-headline"}>
          Präsenz-Veranstaltung
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          Bei den Präsenz-Veranstaltungen wird das Wissen vor Ort zu einem bestimmten
          Datum und einer bestimmten Uhrzeit durch unsere Referenten vermittelt.
        </Box>
      </Grid>
      <Grid>
        <Box component={"div"} className={"learning-events-overview-headline"}>
          Online-Veranstaltung
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          Bei den Online-Veranstaltungen finden sich Referent und Teilnehmer zu einem
          bestimmten Datum und einer bestimmten Uhrzeit in dem Konferenztool Zoom® in
          Echtzeit zusammen.
        </Box>
      </Grid>
      <Grid>
        <Divider />
        <Box component={"div"} className={"learning-events-overview-headline"}>
          E-Learning
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          E-Learning bezeichnet das selbstbestimmte Lernen mit elektronischen Medien.
          Alles, was Sie hierfür benötigen, ist ein Laptop oder Tablet mit einem
          Internetzugang. Hierbei bestimmen Sie selbst, wann Sie lernen und ob Sie die
          einzelnen Einheiten am Stück oder einzeln bearbeiten möchten.
          <br />
          Beispiel: Sie beginnen am Mittwochnachmittag mit der Bearbeitung und schließen
          diese am kommenden Samstag ab.
        </Box>
      </Grid>
      <Grid>
        <Divider />
        <Box component={"div"} className={"learning-events-overview-headline"}>
          Blended-Learning
        </Box>
        <Box component={"p"} className={"learning-events-overview-infotext"}>
          Beim <b>Blended-Learning</b> werden die beiden unterschiedlichen Lernformen
          (Präsenz- <b>oder</b> Online-Veranstaltung <b>und</b> E-Learning) verzahnt und
          zu einer Einheit zusammengeführt. Das E-Learning kann entweder vor der
          Veranstaltung oder nach dieser durchgeführt werden. Das Format des
          Blended-Learnings wird oft eingesetzt, wenn praktische Fähigkeiten und
          theoretisches Wissen gleichzeitig gefragt sind.
        </Box>
      </Grid>
    </>
  );
};

// TODO handle bold in translation

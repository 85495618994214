import { Box } from "@material-ui/core";
import * as React from "react";
import { LumosTimeSvg } from "../../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { Utils } from "../../../../../../services/utils";

interface EventItemDateTimeProps {
  date: Date;
}

const EventItemDateTime: React.FC<EventItemDateTimeProps> = ({ date }) => {
  return (
    <>
      <Box style={{ marginLeft: "5px" }} />
      <LumosTimeSvg /> {Utils.formatToLocalTime(date)}
    </>
  );
};

export default EventItemDateTime;

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import useInterval from "../../hooks/UseInterval";
import CustomButton from "../../theming/CustomButton";
import styles from "./../../../styles/custom-styles.module.scss";
import { useTranslation } from "react-i18next";

const MAX_COUNTER = 20000;
const CHECK_EACH_SECOND = 1000;

interface AlertDialogProps {
  disableBackdrop: boolean;
}

const AlertDialog: React.FC<AlertDialogProps> = (props) => {
  const { t } = useTranslation(["common", "snackbars"]);
  const dialog = useSelector((state: AppState) => state.dialog);
  const [open, setOpen] = React.useState(false);

  const [countdown, setCountdown] = React.useState(MAX_COUNTER / CHECK_EACH_SECOND);

  const [countdownisActive, setCountdownisActive] = React.useState(false);

  useInterval(
    () => {
      const newCount = countdown - 1;
      setCountdown(newCount);
      if (newCount === 0) {
        setCountdownisActive(false);
        if (dialog.dialog.action) {
          dialog.dialog.action();
        }
      }
    },
    countdownisActive && open ? CHECK_EACH_SECOND : null
  );

  const handleClose = useCallback(() => {
    if (!props.disableBackdrop) {
      setOpen(false);
      setCountdown(MAX_COUNTER / CHECK_EACH_SECOND);
    }
  }, [props.disableBackdrop]);

  useEffect(() => {
    if (dialog.show) {
      setOpen(dialog.show);
      if (dialog.redirect) setCountdownisActive(dialog.redirect);
    } else {
      handleClose();
    }
  }, [dialog, handleClose]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={dialog.size}
        open={open}
        onClose={props.disableBackdrop ? () => false : handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialog.dialog.title}</DialogTitle>
        <DialogContent>
          <>
            {dialog.dialog.message}
            {dialog.redirect && (
              <>
                <span>{t("dialogues.alertRedirect.note", { ns: "layout" })}</span>
                <br />
                <Typography variant={"body1"}>
                  <br />
                  {t("dialogues.alertRedirect.countdown", {
                    countdown: countdown,
                    ns: "layout",
                  })}
                </Typography>
              </>
            )}
          </>
        </DialogContent>
        <DialogActions>
          {!dialog.simple && (
            <Button
              onClick={handleClose}
              size="medium"
              variant="contained"
              color="secondary"
            >
              {t("forms.buttons.cancel", { ns: "common" })}
            </Button>
          )}
          {dialog.buttonAppearance === "default" && (
            <Button
              onClick={() => {
                if (dialog.dialog.action) {
                  dialog.dialog.action();
                }
                handleClose();
              }}
              style={{ width: "auto", minWidth: "100px" }}
              size="medium"
              variant="contained"
              color="primary"
              autoFocus
            >
              {dialog.dialog.confirmButtonText}
            </Button>
          )}
          {dialog.buttonAppearance === "delete" && (
            <CustomButton
              onClick={() => {
                if (dialog.dialog.action) {
                  dialog.dialog.action();
                }
                handleClose();
              }}
              customColor={styles.red}
              textColor={"#fff"}
              hoverColor={styles["red-dark"]}
            >
              {dialog.dialog.confirmButtonText}
            </CustomButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;

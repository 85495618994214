import * as React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import logo from "../../../../assets/general/logo_amanzii_print.png";
import { MyEventListData } from "../../../../models/eventData";
import { UserData } from "../../../../models/userData";
import "./MyEventsPrintList.scss";
import { mapToParticipationStatusesString } from "../../../../models/enums/participationStatus.enum";
import { mapToTrainingTypeString } from "../../../../models/enums/trainingType.enum";

type props = {
  eventList: MyEventListData[];
  userData?: UserData;
};

export class MyEventsPrintList extends React.PureComponent<props> {
  public render() {
    return (
      <Grid container style={{ width: "790px" }}>
        <Grid item style={{ width: "150px" }}>
          <img src={logo} alt="amanzii logo" style={{ height: "50px" }} />
        </Grid>
        <Grid item style={{ width: "640px" }}>
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div className={"myeventslist-print-title"}>Meine Buchungen</div>
              <div className={"myeventslist-print-date"}>
                Stand: {new Date().toLocaleDateString()}
              </div>
            </Grid>
            <Grid item xs={1} className={"myeventslist-print-lable"}>
              Name
            </Grid>
            <Grid item xs={5} className={"myeventslist-print-value"}>
              {this.props.userData ? this.props.userData.fullname : ""} (
              {this.props.userData ? this.props.userData.username : ""})
            </Grid>
            <Grid item xs={1} className={"myeventslist-print-lable"}>
              E-Mail
            </Grid>
            <Grid item xs={5} className={"myeventslist-print-value"}>
              {this.props.userData ? this.props.userData.email : ""}
            </Grid>
            <Grid item xs={1} className={"myeventslist-print-lable"}>
              LANR
            </Grid>
            <Grid item xs={5} className={"myeventslist-print-value"}>
              {this.props.userData ? this.props.userData.lanr : ""}
            </Grid>
            <Grid item xs={1} className={"myeventslist-print-lable"}>
              EFN
            </Grid>
            <Grid item xs={5} className={"myeventslist-print-value"}>
              {this.props.userData ? this.props.userData.efn : ""}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableContainer>
            <Table>
              {this.props.eventList.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell>Sie haben noch keine eigenen Veranstaltungen</TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <>
                  <TableHead>
                    <TableRow className={"myeventslist-print-tablecell"}>
                      <TableCell>#</TableCell>
                      <TableCell>Titel</TableCell>
                      <TableCell>Beginn</TableCell>
                      <TableCell>Ende</TableCell>
                      <TableCell>Ort</TableCell>
                      <TableCell>Typ</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.eventList.map((event, index) => {
                      return (
                        <TableRow className={"myeventslist-print-tablecell"} key={index}>
                          <TableCell>{event.event.public_id}</TableCell>
                          <TableCell>{event.event.title}</TableCell>
                          <TableCell>
                            {new Date(event.event.begin).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {new Date(event.event.end).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{event.event.location}</TableCell>
                          <TableCell>
                            {mapToTrainingTypeString(event.event.training_type)}
                          </TableCell>
                          <TableCell>
                            {mapToParticipationStatusesString(event.status)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>
              )}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

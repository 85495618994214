import { faEdit, faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Fade,
  Grid,
  Tooltip,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { pinboardNoAlertService } from "../../../../../../api";
import {
  mapToPopupTitle,
  PinboardPopupType,
} from "../../../../../../models/enums/pinboardType.enum";
import { UserType } from "../../../../../../models/enums/userType.enum";
import { Pagination } from "../../../../../../models/pagination";
import {
  PinboardCommentsListData,
  PinboardListData,
} from "../../../../../../models/pinboardData";
import { AppState } from "../../../../../../redux";
import useMetaData, { formatDateInfo } from "../../../../../hooks/usePinboardMetaData";
import "../../../../../../pages/core/pinboard/Pinboard.scss";
import PinboardAnswerButton from "./PinboardAnswerButton";
import PinboardComment from "./PinboardComment";
import PinboardFollowButton from "./PinboardFollowButton";
import PinboardPopup from "./PinboardPopup";
import { PinboardText } from "./PinboardText";

interface IPinboardTopicProps {
  question: PinboardListData;
  showArchive: boolean;
}

export const COMMENTS_PAGE_SIZE = 5;

const PinboardTopic: React.FunctionComponent<IPinboardTopicProps> = (props) => {
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const initialCommentsPage: Pagination<PinboardCommentsListData> = useMemo(() => {
    return {
      count: 0,
      next: "",
      previous: "",
      results: [],
    };
  }, []);
  const [commentsPackets, setCommentsPackets] = useState([initialCommentsPage]);
  const [packetCount, setPacketCount] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [open, setOpen] = useState(false);
  const [popupType, setPopupType] = useState(PinboardPopupType.UpdateQuestion);

  const isSelf = props.question.created_by === currentUser?.id;

  const closeAnswer = () => {
    setExpanded(false);
  };

  function getTitleColor() {
    if (accessibility.monoMode && !props.question.is_archived) {
      return "#000";
    }
    if (accessibility.monoMode && props.question.is_archived) {
      return "#fff";
    }
    if (!accessibility.monoMode && props.question.is_archived) {
      return undefined;
    }
    if (!accessibility.monoMode && isSelf) {
      return "#fff";
    }
  }

  const setNextComments = useCallback(
    (page: number) => {
      if (expanded && page > 0) {
        pinboardNoAlertService
          .getPinboardComments(page, props.question.id.toString(), props.showArchive)
          .then((response: Pagination<PinboardCommentsListData>) => {
            if (response.results.length === 0) {
              setNoMore(true);
            } else {
              setCommentsPackets((oldArray: Pagination<PinboardCommentsListData>[]) => [
                ...oldArray,
                response,
              ]);
              setNoMore(false);
            }
          })
          .catch(() => {
            setReadMore(false);
            setNoMore(true);
          });
      } else {
        setReadMore(false);
      }
    },
    [props.question.id, expanded, props.showArchive]
  );

  useEffect(() => {
    if (expanded === false) {
      setCommentsPackets([initialCommentsPage]);
      setReadMore(false);
      setNoMore(false);
      setPacketCount(1);
    } else {
      setNextComments(packetCount);
    }
  }, [expanded, initialCommentsPage, packetCount, setNextComments]);

  useEffect(() => {
    if (commentsPackets[packetCount]) {
      setReadMore(commentsPackets[packetCount].next === null ? false : true);
    }
  }, [commentsPackets, packetCount]);

  return (
    <Grid container>
      <Grid item md={9}>
        <Card
          className={accessibility.monoMode ? "pinboard-card-mono" : "pinboard-card"}
        >
          <CardHeader
            titleTypographyProps={{
              variant: "h6",
              style: {
                color: getTitleColor(),
              },
            }}
            className={
              (accessibility.monoMode
                ? "pinboard-cardheader-mono"
                : "pinboard-cardheader") +
              (isSelf ? "-author " : "") +
              (props.question.is_archived ? "-archived " : "")
            }
            action={
              currentUser && (
                <Box
                  className={
                    accessibility.monoMode ? "pinboard-button-mono" : "pinboard-button"
                  }
                >
                  {currentUser?.id === props.question.created_by && (
                    <Tooltip
                      arrow
                      title={mapToPopupTitle(PinboardPopupType.UpdateQuestion)}
                    >
                      <Button
                        onClick={() => {
                          setPopupType(PinboardPopupType.UpdateQuestion);
                          setOpen(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="#fff"
                          style={{ margin: "5px" }}
                        />
                      </Button>
                    </Tooltip>
                  )}
                  {[UserType.Employee, UserType.Apprentice].includes(
                    currentUser.user_type
                  ) && (
                    <Box display="flex">
                      <Tooltip
                        arrow
                        title={mapToPopupTitle(
                          props.question.is_archived
                            ? PinboardPopupType.UnarchiveQuestion
                            : PinboardPopupType.ArchiveQuestion
                        )}
                      >
                        <Button
                          onClick={() => {
                            setPopupType(
                              props.question.is_archived
                                ? PinboardPopupType.UnarchiveQuestion
                                : PinboardPopupType.ArchiveQuestion
                            );
                            setOpen(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              props.question.is_archived ? faPlusCircle : faMinusCircle
                            }
                            color="#fff"
                            style={{ margin: "5px" }}
                          />
                        </Button>
                      </Tooltip>
                      {props.question.is_archived && (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          style={{ color: "#fff" }}
                        >
                          <LockIcon
                            fontSize="small"
                            style={{ marginTop: "-2px", marginRight: "0.1em" }}
                          />
                          Archiviert am{" "}
                          {formatDateInfo(new Date(props.question.last_changed_on))}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )
            }
            title={props.question.title}
          />
          <PinboardPopup
            question={props.question}
            open={open}
            close={() => setOpen(false)}
            popupType={popupType}
          />
          <CardContent>
            <Box
              className={
                (accessibility.monoMode ? "pinboard-muted-mono " : "pinboard-muted ") +
                (isSelf ? "pinboard-self" : "")
              }
            >
              {useMetaData("Beitrag", props.question, isSelf)}
            </Box>
            <PinboardText content={props.question.post} />
          </CardContent>
          {props.question.has_answer && (
            <Button
              color="primary"
              style={{ margin: "1em" }}
              className="pinboard-details"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Antworten schließen" : "Antworten öffnen"}
            </Button>
          )}
        </Card>
        {expanded &&
          commentsPackets.map((packet, i) => {
            return packet.results.map((comment, index) => {
              return (
                <PinboardComment
                  key={index}
                  index={index + (i % 2)}
                  comment={comment}
                  question={props.question}
                  close={() => setExpanded(false)}
                />
              );
            });
          })}
        {readMore && (
          <Fade in={true} style={{ transitionDelay: "500ms" }}>
            <Button
              color="primary"
              onClick={() => setPacketCount((count) => ++count)}
              className="pinboard-details"
              style={{ marginRight: "20px" }}
            >
              Weitere Antworten anzeigen
            </Button>
          </Fade>
        )}
        {noMore && packetCount === 1 && (
          <Box mt={2} mb={3} display="flex" justifyContent="center">
            Derzeit liegt keine Antwort vor.
          </Box>
        )}
      </Grid>
      <Grid item md={3} style={{ marginTop: "7px" }}>
        <PinboardFollowButton question={props.question} />
        <PinboardAnswerButton question={props.question} close={closeAnswer} />
      </Grid>
    </Grid>
  );
};

export default PinboardTopic;

import { Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { usersService } from "../../../api";
import { SalutationType } from "../../../models/enums/salutationType.enum";
import { UserType } from "../../../models/enums/userType.enum";
import { UserData } from "../../../models/userData";
import CustomTextField from "../../forms/CustomTextField";
import { NIL as NIL_UUID } from "uuid";

interface SearchUserFieldProps {
  onChangeUserId: (id: string) => void;
  initialUser?: UserData;
}

export const SearchUserField: React.FC<SearchUserFieldProps> = (props) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchOptions, setSearchOptions] = useState<UserData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [autocompleteValue, setAutocompleteValue] = useState<UserData>({
    academic_title: "",
    id: NIL_UUID,
    first_name: "",
    last_name: "",
    birthday: "",
    bsnr: "",
    efn: "",
    email: "",
    fullname: "",
    participation_types: [],
    username: "",
    lanr: "",
    mobile: "",
    phone: "",
    salutation: SalutationType.Neutral,
    user_type: UserType.Undefined,
    address: {
      city: "",
      street: "",
      zip_code: "",
      country: "",
    },
  });
  const [editedSearch, setEditedSearch] = useState<boolean>(false);

  const handleSearchQueryChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedSearch(true);
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let query = searchQuery;

      if (query != null) {
        (async () => {
          if (query === searchQuery && query.length > 0) {
            var results = await usersService.getStatistics("?search=" + query);
            setSearchOptions(results.map((x) => x.user));
          }
        })();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, setSearchOptions]);

  useEffect(() => {
    if (!searchOpen) {
      setSearchOptions([]);
    }
  }, [searchOpen]);

  useEffect(() => {
    if (!editedSearch && props.initialUser) {
      setAutocompleteValue(props.initialUser);
    }
  }, [editedSearch, setAutocompleteValue, props.initialUser]);

  return (
    <Autocomplete
      freeSolo
      id="user"
      noOptionsText={"Kein Nutzer gefunden"}
      // react shows loadingText if no options provided
      loadingText={"Keine Nutzer gefunden"}
      value={autocompleteValue}
      open={searchOpen}
      onOpen={() => {
        setSearchOpen(true);
      }}
      onClose={() => {
        setSearchOpen(false);
      }}
      // backend will filter the results
      filterOptions={(options, _) => {
        return options;
      }}
      onChange={(event, item) => {
        if (typeof item !== "string") {
          if (item) {
            props.onChangeUserId(item?.id);
          }
        }
      }}
      options={searchOptions}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        return typeof option !== "string" && option.id !== NIL_UUID
          ? `${
              option.academic_title === null ? "" : option.academic_title + " "
            }${option.fullname}`
          : option.toString();
      }}
      renderOption={(option) => (
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            {`${
              option.academic_title === null ? "" : option.academic_title + " "
            }${option.fullname}${option.username ? " (" + option.username + ")" : ""}`}
            <br />
            {option.lanr && "LANR: " + option.lanr}
          </Grid>
        </Grid>
      )}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          label="Nutzer"
          name="user"
          value={searchQuery}
          onChange={handleSearchQueryChange}
        />
      )}
    />
  );
};

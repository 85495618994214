import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { FieldAttributes, useField } from "formik";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";

interface CustomCheckboxProps {
  label?: string | React.ReactNode;
  small?: boolean;
}

export interface StyleProps {
  monoMode: boolean;
}

const useStyles = makeStyles({
  small: {
    transform: "scale(0.75)",
  },
  large: {},
  icon: {
    width: 30,
    height: 30,
    transition: "opacity 300ms, border 300ms",
  },
  checkedIcon: {
    width: 29,
    height: 15,
    position: "absolute",
    transform: "rotate(-45deg)",
    transition: "border-color 300ms, background-color 300ms",
    border: (props: StyleProps) =>
      props.monoMode ? "3px solid #000" : "3px solid var(--secondary-color)",
    borderTop: (props: StyleProps) => (props.monoMode ? "none" : "none"),
    borderRight: (props: StyleProps) => (props.monoMode ? "none" : "none"),
    top: "0px",
    left: "3px",
  },
});

const CustomCheckbox: React.FunctionComponent<
  FieldAttributes<{}> & CustomCheckboxProps & CheckboxProps
> = ({ label, ...props }) => {
  const [field, meta] = useField<{}>(props);
  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text
  const [focused, setFocused] = React.useState(false);
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const monoMode = accessibility.monoMode;
  const classes = useStyles({ monoMode: monoMode });

  const small = props.small;
  props.small = undefined; /* gives warning when passed to Checkbox */
  return (
    <>
      <FormControlLabel
        style={{ margin: 0 }}
        className={small ? classes.small : classes.large}
        control={
          <Checkbox
            {...field}
            {...props}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
            checked={
              field.value === undefined ? props.checked : (field.value as boolean)
            }
            color="default"
            className={
              focused ? (accessibility.monoMode ? "focused-mono" : "focused") : ""
            }
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ "aria-label": "checkbox" }}
          />
        }
        label={label && <Box style={{ marginLeft: "20px" }}>{label}</Box>}
      />
      <Box className="error-text">{error}</Box>
    </>
  );
};

export default CustomCheckbox;

import React, { useEffect, useState } from "react";
import * as yup from "yup";
import moment from "moment";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { Box, Button, FormControl, Grid, Typography } from "@material-ui/core";

import "./RegistrationPage.scss";
import { initialData, userNeedsAGuardian } from "./RegistrationPage.utils";
import { UserRegistrationRequestData } from "../../../models/userData";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import { fetchMinimumAge } from "../../../redux/system-settings/actions";
import { usersService } from "../../../api";
import { AppState } from "../../../redux";
import CustomTextField from "../../../components/forms/CustomTextField";
import CustomPasswordField from "../../../components/forms/CustomPasswordField";
import CustomDatepicker from "../../../components/forms/CustomDatepicker";
import routes from "../../../routing/routes";
import i18n from "../../../localization/i18n";
import { GroupMembershipDocumentSection } from "../../../components/core/registration/GroupMembershipDocumentSection";
import { FileObject } from "material-ui-dropzone";
import { MembershipDocumentRequestDto } from "../../../models/groupMembership";
import { groupMembershipService } from "../../../api";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { EMAIL_REQUIRED } from "../../../libs/validation_strings";
import SalutationSelect from "../../../components/forms/selects/SalutationSelect";

/**
 * RegistrationPage component
 * @returns {JSX.Element}
 */
export const RegistrationPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["authentication", "common", "snackbars"]);
  const [code, setCode] = React.useState<string | null>(null);
  const [minimumAge, setMinimumAge] = useState<number>(0);
  const [userMail, setUserMail] = useState<string | undefined>(undefined);

  const minimumAgeFromApi = useSelector((state: AppState) => state.minimumAge);

  const currentDateLocal = new Date();
  const today = currentDateLocal.toDateString();

  const initialGroupMembershipDocumentValues: MembershipDocumentRequestDto = {
    file: "",
    valid_until: today,
    document_has_no_expiry_date: false,
    document_type: "",
  };

  const [groupMembershipDocument, setGroupMembershipDocument] =
    useState<MembershipDocumentRequestDto>(initialGroupMembershipDocumentValues);
  const [files, setFiles] = useState<FileObject[]>([]);

  const [guardianRequired, setGuardianRequired] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [birthday, setBirthday] = useState<MaterialUiPickersDate>();

  const {
    YupValidationFirstName,
    YupValidationLastName,
    YupValidationEmail,
    YupValidationConfirmEmail,
    YupValidationBirthday,
    YupValidationStreet,
    YupValidationStreetNumber,
    YupValidationCity,
    YupValidationZipCode,
    YupValidationConfirmPassword,
    YupValidationGuardianEmail,
    YupValidationSalutation,
    YupValidationConfirmGuardianEmail,
  } = useDynamicYupValidations();

  const registerValidationSchema = yup.object({
    first_name: YupValidationFirstName,
    last_name: YupValidationLastName,
    email: YupValidationEmail,
    email_confirmation: YupValidationConfirmEmail("email"),
    birthday: YupValidationBirthday,
    salutation: YupValidationSalutation,
    street: YupValidationStreet,
    street_number: YupValidationStreetNumber,
    city: YupValidationCity,
    zip_code: YupValidationZipCode,
    password: YupValidationConfirmPassword("confirmPassword"),
    confirmPassword: YupValidationConfirmPassword("password"),
    guardian_email: guardianRequired
      ? YupValidationGuardianEmail(minimumAge, userMail).required(t(EMAIL_REQUIRED))
      : YupValidationGuardianEmail(minimumAge, userMail),
    guardian_email_confirmation: guardianRequired
      ? YupValidationConfirmGuardianEmail("guardian_email").required(t(EMAIL_REQUIRED))
      : YupValidationGuardianEmail(minimumAge, userMail),
  });

  /**
   * Executes the effect hook to extract the verification code from the URL search parameters.
   * If a verification code is found, it sets the code state.
   * @param {object} location - The location object containing the search parameters.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const verificationCode = searchParams.get("code");
    if (verificationCode) setCode(verificationCode);
  }, [location.search]);

  /**
   * Fetches the minimum age from the API.
   * The minimum age is used to determine if a user needs a guardian.
   */
  useEffect(() => {
    dispatch(fetchMinimumAge());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sets the minimum age from the API response to the state.
   */

  useEffect(() => {
    if (!minimumAgeFromApi?.isLoading && minimumAgeFromApi?.minimumAge?.minimum_age) {
      setMinimumAge(minimumAgeFromApi.minimumAge.minimum_age);
    }
  }, [minimumAgeFromApi]);

  /**
   * Submits the registration form.
   * @param values - The form values.
   */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const submitRegister = async (values: any) => {
    let dataUrl = "";

    if (files.length !== 0 && files[0].file) {
      dataUrl = await groupMembershipService.getFileAsDataUrl(files[0].file);
    }
    let validUntilDate = null;
    if (
      groupMembershipDocument.valid_until &&
      !groupMembershipDocument.document_has_no_expiry_date
    ) {
      validUntilDate = moment(groupMembershipDocument.valid_until).format("YYYY-MM-DD");
    }

    let group_membership_document = null;

    if (groupMembershipDocument && dataUrl.length !== 0) {
      group_membership_document = {
        file: dataUrl,
        valid_until: validUntilDate,
        document_has_no_expiry_date: groupMembershipDocument.document_has_no_expiry_date,
        document_type: groupMembershipDocument.document_type,
      };
    }

    const data: UserRegistrationRequestData = {
      first_name: values.first_name,
      last_name: values.last_name,
      salutation: values.salutation,
      email: values.email,
      birthday: moment(values.birthday).format("DD.MM.YYYY"),
      group_keys: values.group_keys,
      street: values.street,
      guardian_email: values.guardian_email,
      street_number: values.street_number,
      zip_code: values.zip_code,
      city: values.city,
      password: values.password,
      verification_code: code || undefined,
      group_membership_document: group_membership_document,
    };

    // Errors are handled in BaseCrudService
    usersService.register(data).then((success) => {
      if (success) {
        enqueueSnackbar(t("profile.accountRegistered", { ns: "snackbars" }), {
          variant: "success",
        });
        history.push(routes.register_success);
      }
    });
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <h4 className="RegistrationHeading">
        {t("registration.header", { ns: "authentication" })}
      </h4>{" "}
      <Typography className="RegistrationText">
        {t("registration.note", { ns: "authentication" })}
      </Typography>
      <Formik
        key={i18n.language}
        initialValues={initialData}
        onSubmit={submitRegister}
        validationSchema={registerValidationSchema}
        validateOnChange
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form className="RegistrationForm">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={SalutationSelect}
                      style={{ width: "100%" }}
                      className="RegistrationPage"
                      fullWidth
                      type="name"
                      name="salutation"
                      id="salutation-registration"
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={CustomTextField}
                      style={{ width: "100%" }}
                      className="RegistrationPage"
                      fullWidth
                      type="name"
                      name="first_name"
                      id="first-name-registration"
                      variant="filled"
                      label={t("profile.firstName", { ns: "common" })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      as={CustomTextField}
                      style={{ width: "100%" }}
                      className="RegistrationPage"
                      fullWidth
                      type="name"
                      name="last_name"
                      id="last-name-registration"
                      variant="filled"
                      label={t("profile.lastName", { ns: "common" })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  as={CustomTextField}
                  style={{ width: "100%" }}
                  className="RegistrationPage"
                  fullWidth
                  type="E-Mail"
                  name="email"
                  id="email-registration"
                  variant="filled"
                  label={t("profile.mail", { ns: "common" })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const lowercaseEmail = e.target.value.toLowerCase();
                    handleChange(e.target.name)(lowercaseEmail);
                    setUserMail(lowercaseEmail);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  as={CustomTextField}
                  style={{ width: "100%" }}
                  className="RegistrationPage"
                  fullWidth
                  type="E-Mail"
                  name="email_confirmation"
                  id="email-confirmation"
                  variant="filled"
                  label={t("profile.mailConfirmation", { ns: "common" })}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Field
                  as={CustomDatepicker}
                  fullWidth
                  autoComplete="birthday"
                  name="birthday"
                  id="birthday-registration"
                  label={t("profile.birthdate", { ns: "common" })}
                  value={values.birthday ?? ""}
                  customChangeHandler={(date: MaterialUiPickersDate) => {
                    setFieldValue("birthday", date);
                    setBirthday(date);
                    const dateString = date ? date.toISOString() : "";
                    setGuardianRequired(userNeedsAGuardian(dateString, minimumAge));
                  }}
                  required
                />
              </Grid>
              {values.birthday
                ? userNeedsAGuardian(values.birthday, minimumAge) && ( // eslint-disable-line
                    <>
                      <Grid item xs={12} lg={6}>
                        <Field
                          as={CustomTextField}
                          style={{ width: "100%", marginBottom: 8 }}
                          className="RegistrationPage"
                          fullWidth
                          type="E-Mail"
                          name="guardian_email"
                          variant="filled"
                          label={t("registration.guardianEmail", {
                            ns: "authentication",
                          })}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const lowercaseEmail = e.target.value.toLowerCase();
                            handleChange(e.target.name)(lowercaseEmail);
                          }}
                          required={guardianRequired}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Field
                          as={CustomTextField}
                          style={{ width: "100%", marginBottom: 8 }}
                          className="RegistrationPage"
                          fullWidth
                          type="E-Mail"
                          name="guardian_email_confirmation"
                          variant="filled"
                          label={t("registration.guardianEmailConfirmation", {
                            ns: "authentication",
                          })}
                          required={guardianRequired}
                        />
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Typography className="GuardianEmailNote">
                          {t("registration.guardianEmailNote", {
                            ns: "authentication",
                          })}
                        </Typography>
                      </Grid>
                    </>
                  )
                : null}
              {/* Disabled only because of LU-561
                <Grid item lg={12}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="group_keys"
                  label="Gruppenschlüssel"
                  name="group_keys"
                />
              </Grid> */}
            </Grid>
            <div className="RegistrationAddress" style={{ width: "100%" }}>
              <h3>{t("profile.addressHeader", { ns: "common" }).toUpperCase()}</h3>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={9}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="street-registration"
                  label={t("profile.street", { ns: "common" })}
                  name="street"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="street-number-registration"
                  label={t("profile.streetNumber", { ns: "common" })}
                  name="street_number"
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="zip-code-registration"
                  label={t("profile.zipCode", { ns: "common" })}
                  name="zip_code"
                />
              </Grid>{" "}
              <Grid item xs={12} lg={9}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="city-registration"
                  label={t("profile.city", { ns: "common" })}
                  name="city"
                />
              </Grid>
            </Grid>

            <div className="RegistrationAdress" style={{ width: "100%" }}>
              <h3>{t("profile.password", { ns: "common" }).toUpperCase()}</h3>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Field
                  as={CustomPasswordField}
                  fullWidth
                  id="password"
                  label={t("profile.password", { ns: "common" })}
                  name="password"
                />
              </Grid>{" "}
              <Grid item xs={12} lg={6}>
                <Field
                  as={CustomPasswordField}
                  fullWidth
                  id="confirm-password"
                  label={t("password.forgotten.confirm", {
                    ns: "authentication",
                  })}
                  name="confirmPassword"
                />
              </Grid>
            </Grid>
            {process.env.REACT_APP_ENABLE_GROUP_MEMBERSHIP_DOCUMENT_UPLOAD_BY_DEFAULT === 'true' && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box>
                  <GroupMembershipDocumentSection
                    groupMembershipDocument={groupMembershipDocument}
                    setGroupMembershipDocument={setGroupMembershipDocument}
                    files={files}
                    setFiles={setFiles}
                  />
                </Box>
              </Grid>
            </Grid>
            )}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl className="registrationButton" fullWidth>
                  <Button
                    id="login"
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disableRipple
                  >
                    {t("login", { ns: "authentication" })}
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

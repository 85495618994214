import React, { useEffect } from "react";
import { Box, Button, Divider, Grid, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { PAGE_SIZE } from "../../../../models/pagination";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux";
import { useHistory } from "react-router";
import {
  clearTaskTemplate,
  deleteTaskTemplate,
  getAllTaskTemplates,
  setPageCount,
} from "../../../../redux/templates/tasks/actions";
import routes from "../../../../routing/routes";
import { showDialog } from "../../../../redux/dialogs/actions";
import { mapToTaskTypeString } from "../../../../models/enums/taskType.enum";

export const TaskTemplates: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const templates = useSelector((state: AppState) => state.taskTemplates);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const loadData = React.useCallback(() => {
    dispatch(getAllTaskTemplates(templates.currentPage));
  }, [templates.currentPage, dispatch]);

  useEffect(loadData, [loadData]);
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(clearTaskTemplate());
            history.push(routes.templates_new_task);
          }}
        >
          Neue Standardaufgabe
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0} className={"event-templates-list-header"}>
          <Grid item xs={8}>
            Standardaufgaben
          </Grid>
          <Grid item xs={2}>
            Veranstaltungsart
          </Grid>
          <Grid item xs={2}>
            Aktion
          </Grid>
        </Grid>
        {templates.taskTemplateList.results.map((task, index) => {
          return (
            <React.Fragment key={index}>
              <Grid container spacing={0} className={"event-templates-list-item"}>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  {task.description}
                </Grid>
                <Grid item xs={2} style={{ margin: "auto" }}>
                  {mapToTaskTypeString(task.task_type)}
                </Grid>
                <Grid item xs={1}>
                  <Tooltip arrow title={"Vorlage bearbeiten"}>
                    <EditIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(clearTaskTemplate());
                        history.push(routes.templates_edit_task + "/" + task.id);
                      }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip arrow title={"Vorlage löschen"}>
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      className={
                        accessibility.monoMode
                          ? "documents-list-item-delButton-mono"
                          : "documents-list-item-delButton"
                      }
                      onClick={() => {
                        dispatch(
                          showDialog({
                            title: "Standardaufgabe löschen",
                            message: (
                              <>
                                Sind Sie sicher, dass Sie die Standardaufgabe{" "}
                                <b>{task.description}</b> löschen möchten?
                              </>
                            ),
                            action: () => {
                              dispatch(deleteTaskTemplate(task.id));
                            },
                          })
                        );
                      }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          );
        })}
      </Grid>
      <Grid container style={{ marginTop: "10px" }}>
        <Box component={"div"} style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(templates.taskTemplateList.count / PAGE_SIZE)}
            page={templates.currentPage}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

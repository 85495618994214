import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { history } from "../../../routing/history";
import { OrganizationData } from "../../../models/organizationData";
import { getOrganizationById } from "../../../redux/organization/actions";
import { MembershipsManagement } from "../../../components/core/profile-settings/organization/membership/MembershipsManagement";
import CrudOrganization from "../../../components/core/profile-settings/organization/CrudOrganization";
import AddMembership from "../../../components/core/profile-settings/organization/membership/AddMembership";
import TabsLumos from "../../../components/theming/tabs/TabsLumos";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import routes from "../../../routing/routes";
import InvoiceManagement from "../../../components/core/profile-settings/organization/invoice/InvoiceManagement";

interface RouteParams {
  id: string;
}

/**
 * Component for managing organization details and memberships.
 * @returns JSX.Element
 */
export const OrganizationAdminPage = () => {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation(["organization", "snackbars", "invoices"]);
  const dispatch = useDispatch();
  const organizations = useSelector((state: AppState) => state.organizations);
  const user = useSelector((state: AppState) => state.user);
  const [organization, setOrganization] = useState<OrganizationData | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      dispatch(getOrganizationById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (organizations?.organizationData?.length > 0) {
      setOrganization(organizations?.organizationData[0]);
    } else {
      setLoading(false);
    }
  }, [organizations]);

  useEffect(() => {
    if (organization && user) {
      const isAdmin = organization.admins.some(
        (admin) => admin?.admin_id === user?.currentUser?.id
      );
      if (isAdmin) {
        setLoading(false);
      } else {
        history.push(routes.home);
      }
    }
  }, [organization, user]);

  const tabs = [
    {
      label: t("membership.manageMemberships", { ns: "organization" }),
      content: <MembershipsManagement organizationId={id} />,
    },
    {
      label: t("membership.addMemberships", { ns: "organization" }),
      content: <AddMembership organizationId={id} />,
    },
    {
      label: t("invoice.manageInvoices", { ns: "organization" }),
      content: <InvoiceManagement organizationId={id} />,
    },
    {
      label: t("crud.editOrganization", { ns: "organization" }),
      content: organization && <CrudOrganization organizationData={organization} />,
    },
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : !loading && organization ? (
        <>
          <HeadingLumos>{organization?.name}</HeadingLumos>
          <TabsLumos tabs={tabs} />
        </>
      ) : (
        <Typography>{t("noOrganization", { ns: "organization" })}</Typography>
      )}
    </>
  );
};

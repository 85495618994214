import { FormHelperText, makeStyles } from "@material-ui/core";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import { convertToRaw } from "draft-js";
import { useFormikContext } from "formik";
import MUIRichTextEditor from "mui-rte";
import React from "react";
import { ELearningFormData } from "../../../../models/elearning";
import { EventStatus } from "../../../../models/enums/eventStatus.enum";
import { EventFormData } from "../../../../models/eventData";
import "./Content.scss";

export interface StyleProps {
  monoMode: boolean;
}

const useStyles = makeStyles({
  errorRoot: {
    borderBottom: "2px solid red",
  },
  error: {
    backgroundColor: "var(--red-accent-3)",
  },
});

export const Content: React.FC = () => {
  const classes = useStyles();
  const { values, setFieldValue, errors, isValid, submitCount, dirty } =
    useFormikContext<EventFormData | ELearningFormData>();

  function checkValidation() {
    return errors.description && !isValid && submitCount > 0 && dirty;
  }

  return (
    <>
      <MUIRichTextEditor
        onChange={(values) => {
          setFieldValue("description", values.getCurrentContent());
        }}
        readOnly={values.status === EventStatus.Completed}
        defaultValue={JSON.stringify(convertToRaw(values.descriptionState))}
        label={`Beschreiben Sie Ihre Veranstaltung ${
          values.status === EventStatus.Published ? "*" : ""
        }`}
        classes={{
          root: checkValidation() ? classes.errorRoot : undefined,
          editor: checkValidation() ? classes.error : undefined,
        }}
        controls={[
          "title",
          "bold",
          "italic",
          "underline",
          "undo",
          "redo",
          "numberList",
          "bulletList",
          "indentLine",
          "link",
        ]}
        customControls={[
          {
            name: "indentLine",
            icon: <FormatIndentIncreaseIcon />,
            type: "inline",
            inlineStyle: {
              marginLeft: "20px",
            },
          },
        ]}
        draftEditorProps={{
          spellCheck: true,
          stripPastedStyles: true,
        }}
      />
      {checkValidation() && (
        <FormHelperText variant="filled" error={checkValidation()}>
          {errors.description}
        </FormHelperText>
      )}
    </>
  );
};

// TODO no translation yet

import { UserType } from "../../../../models/enums/userType.enum";
import routes from "../../../../routing/routes";

/**
 * Interface for navigation data representing a single navigation item.
 */
export interface NavigationData extends BaseNavData {
  showSubRoutes?: boolean; // determines whether the routes should be shown in the sidebar as sub routes
  subRoutes?: NavigationData[]; // all subroutes that exist (are needed for breadcrumbs)
}

/**
 * Interface for the base navigation data.
 */
export interface BaseNavData {
  key: string;
  text: string;
  route: string;
}

/**
 * Define navigation items.
 */
const events: NavigationData = {
  key: "veranstaltungen",
  text: "events.main",
  route: routes.events,
};

const organizations: NavigationData = {
  key: "organization",
  text: "organization",
  route: routes.organization,
  subRoutes: [
    {
      key: "bearbeiten",
      text: "organization.edit",
      route: routes.organization_admin_id,
    },
  ],
};

const eventsWithSubRoutes: NavigationData = {
  ...events,
  subRoutes: [
    {
      key: "anlegen",
      route: routes.event_create,
      text: "events.create",
    },
    {
      key: "bearbeiten",
      route: routes.event_edit,
      text: "events.edit",
    },
    {
      key: "e-learning-anlegen",
      route: routes.elearning_create,
      text: "events.eLearningCreate",
    },
    {
      key: "blended-learning-anlegen",
      route: routes.blended_learning_create,
      text: "events.blendedCreate",
    },
    {
      key: "e-learning-bearbeiten",
      route: routes.elearning_edit_id,
      text: "events.eLearningEdit",
      subRoutes: [
        {
          key: "pinnwand",
          route: routes.pinboard_staff_elearning,
          text: "events.pinboard",
        },
      ],
    },
    {
      key: "blended-learning-bearbeiten",
      route: routes.blended_learning_edit_id,
      text: "events.blendedEdit",
      subRoutes: [
        {
          key: "pinnwand",
          route: routes.pinboard_staff_blended_learning,
          text: "events.pinboard",
        },
      ],
    },
    {
      key: "lernangebote",
      route: routes.learning_events_overview,
      text: "events.eventsOverview",
    },
  ],
};

const teilnahmeAnElearning: NavigationData = {
  text: "events.eLearningEdit",
  key: "e-learning-test",
  route: routes.elearning_test_id,
};

const authentisierungElearning: NavigationData = {
  text: "authentication",
  key: "authentisierung",
  route: routes.elearning_test_id,
};

const myEvents: NavigationData = {
  key: "meine-buchungen",
  text: "bookings.main",
  route: routes.my_events,
  subRoutes: [
    {
      key: "details",
      text: "details",
      route: routes.my_events_details,
    },
    {
      key: "details-e-learning",
      text: "details",
      route: routes.my_events_details_elearning_id,
    },
    {
      key: "pinnwand",
      route: routes.pinboard_participant,
      text: "events.pinboard",
    },
  ],
};

const myInvoices: NavigationData = {
  key: "meine-rechnungen",
  text: "invoices",
  route: routes.my_invoices,
  subRoutes: [
    {
      key: "details",
      text: "details",
      route: routes.my_events_details,
    },
  ],
};

const myLectures: NavigationData = {
  key: "meine-vortraege",
  text: "lectures",
  route: routes.my_lectures,
  subRoutes: [
    {
      key: "details",
      text: "details",
      route: routes.my_lectures_details_event_id,
      subRoutes: [
        {
          key: "pinnwand",
          text: "events.pinboard",
          route: routes.pinboard_speaker,
        },
      ],
    },
    {
      key: "details-e-learning",
      text: "details",
      route: routes.my_lectures_details_elearning_id,
      subRoutes: [
        {
          key: "pinnwand",
          text: "events.pinboard",
          route: routes.pinboard_speaker,
        },
      ],
    },
  ],
};

const tasks: NavigationData = {
  key: "aufgaben",
  text: "tasks.main",
  route: routes.tasks,
  subRoutes: [
    {
      key: "erstellen",
      route: routes.tasks_new,
      text: "tasks.create",
    },
    {
      key: "bearbeiten",
      route: routes.tasks_edit,
      text: "tasks.edit",
    },
  ],
};

const templates: NavigationData = {
  key: "vorlagen",
  text: "templates.main",
  route: routes.templates,
  subRoutes: [
    {
      key: "aufgaben-erstellen",
      route: routes.templates_new_task,
      text: "templates.taskCreate",
    },
    {
      key: "aufgaben-bearbeiten",
      route: routes.templates_edit_task,
      text: "templates.taskEdit",
    },
    {
      key: "veranstaltung-erstellen",
      route: routes.templates_new_event,
      text: "templates.eventCreate",
    },
    {
      key: "veranstaltung-bearbeiten",
      route: routes.templates_edit_event,
      text: "templates.eventEdit",
    },
  ],
};

const activityHistory: NavigationData = {
  key: "aktivitaetenhistorie",
  text: "activityHistory",
  route: routes.activityHistory,
};

const settings: NavigationData = {
  key: "einstellungen",
  text: "settings",
  route: routes.settings,
};

const logout: NavigationData = {
  key: "logout",
  text: "logout",
  route: routes.login,
};

const login: NavigationData = {
  key: "login",
  text: "login",
  route: routes.login,
};

const generalTermsAndConditions: NavigationData = {
  key: "agb",
  text: "generalTermsAndConditions",
  route: routes.general_terms_and_conditions,
};

const webAccessibility: NavigationData = {
  key: "barrierefreiheit",
  text: "webAccessibility",
  route: routes.web_accessibility,
};

const contact: NavigationData = {
  key: "kontakt",
  text: "contact",
  route: routes.contact,
};

const privacyPolicy: NavigationData = {
  key: "datenschutz",
  text: "privacy",
  route: routes.privacy_policy,
};

const legalNotice: NavigationData = {
  key: "impressum",
  text: "legalNotice",
  route: routes.legal_notice,
};

const favorites: NavigationData = {
  key: "favoriten",
  text: "favorites",
  route: routes.favorites,
};

const linkConfirmation: NavigationData = {
  key: "konto-verknuepfen",
  text: "linkConfirmation",
  route: routes.link_confirmation,
};

const eLearningDashboard: NavigationData = {
  key: "e-learning-dashboard",
  text: "events.eLearning",
  route: routes.elearning_dashboard,
};

const eLearningLms: NavigationData = {
  key: "e-learning-lms",
  text: "events.eLearning",
  route: routes.elearning_lms,
};

const checkout: NavigationData = {
  key: "buchung",
  text: "bookingProcess.main",
  route: routes.booking,
  subRoutes: [
    {
      key: "checkout",
      route: routes.checkout,
      text: "bookingProcess.checkout",
      subRoutes: [
        {
          key: "paypal",
          route: routes.paypal,
          text: "bookingProcess.paypal",
          subRoutes: [
            {
              key: "finish",
              route: routes.afterCheckoutPaypal,
              text: "bookingProcess.finish",
            },
          ],
        },
        {
          key: "rechnung",
          route: routes.bill,
          text: "bookingProcess.invoice",
          subRoutes: [
            {
              key: "finish",
              route: routes.afterCheckoutBill,
              text: "bookingProcess.finish",
            },
          ],
        },
        {
          key: "kartenzahlung",
          route: routes.cardPayment,
          text: "bookingProcess.card",
          subRoutes: [
            {
              key: "finish",
              route: routes.afterCheckoutBill,
              text: "bookingProcess.finish",
            },
          ],
        },
      ],
    },
    {
      key: "antwort",
      route: routes.waiting_list_response,
      text: "response",
    },
  ],
};

const admin: NavigationData = {
  key: "admin",
  text: "userType.admin",
  route: routes.admin,
};

const maintenance: NavigationData = {
  key: "wartungsarbeiten",
  text: "maintenance",
  route: routes.maintenance,
};

const commonRoutes: NavigationData[] = [organizations, settings, logout];
const commonRoutesNotLoggedIn: NavigationData[] = [events, maintenance];

const navigationItemsEmployee: NavigationData[] = [
  eventsWithSubRoutes,
  tasks,
  templates,
  activityHistory,
  ...commonRoutes,
];

const navigationItemsSpeaker: NavigationData[] = [events, myLectures, ...commonRoutes];

const navigationItemsUser: NavigationData[] = [
  eventsWithSubRoutes,
  myEvents,
  myInvoices,
  ...commonRoutes,
];

const navigationItemsTestUser: NavigationData[] = [
  eventsWithSubRoutes,
  myEvents,
  myInvoices,
  ...commonRoutes,
];

//use when user roles are ready

export function getNavigationByRole(userType?: UserType): NavigationData[] {
  switch (userType) {
    case UserType.Undefined:
      return commonRoutesNotLoggedIn;
    case UserType.Admin:
      return [logout];
    case UserType.Speaker:
      return navigationItemsSpeaker;
    case UserType.Participant:
      return navigationItemsUser;
    case UserType.Employee:
    case UserType.Apprentice:
      return navigationItemsEmployee;
    case UserType.TestParticipant:
      return navigationItemsTestUser;
    default:
      return commonRoutesNotLoggedIn;
  }
}

export function getAllRoutes(): NavigationData[] {
  return [
    eventsWithSubRoutes,
    myEvents,
    eLearningDashboard,
    eLearningLms,
    myLectures,
    tasks,
    activityHistory,
    templates,
    favorites,
    myInvoices,
    linkConfirmation,
    ...commonRoutes,
    generalTermsAndConditions,
    webAccessibility,
    privacyPolicy,
    contact,
    legalNotice,
    login,
    checkout,
    admin,
    maintenance,
    teilnahmeAnElearning,
    authentisierungElearning,
    organizations,
  ];
}

import { AppState } from "../../../../../../redux";
import { CertificateTypeData } from "../../../../../../models/certificateTypeData";
import { CertificateTypesDropDown } from "./CertificateTypesDropDown";
import CustomCheckbox from "../../../../../forms/CustomCheckbox";
import { ELearningFormData } from "../../../../../../models/elearning";
import { EventFormData } from "../../../../../../models/eventData";
import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";

interface CertificateTypeProps {
  allCertificateTypes: CertificateTypeData[];
  eventCertificateType: CertificateTypeData;
  isEventCertificationActivated: boolean;
}

export const CertificateTypes: React.FC<CertificateTypeProps> = ({
  allCertificateTypes,
  eventCertificateType,
  isEventCertificationActivated,
}) => {
  const [isCertificationActivated, setIsCertificationActivated] = useState(
    isEventCertificationActivated
  );

  const editEvent = useSelector((state: AppState) => state.event.editEvent);

  const { values, setFieldValue } = useFormikContext<
    EventFormData | ELearningFormData
  >();

  return (
    <div style={{ padding: "10px 0 10px 0" }}>
      <Grid item lg={12} xs={6} sm={3} style={{ padding: "10px 0 10px 0" }}>
        <CustomCheckbox
          name="certtificateActivated"
          label={<>Zertifizierung</>}
          checked={isCertificationActivated}
          onChange={() => {
            setIsCertificationActivated(!isCertificationActivated);
            setFieldValue("is_certification_selected", !isCertificationActivated);
          }}
        />
      </Grid>
      {isCertificationActivated && (
        <CertificateTypesDropDown
          allCertificateTypes={allCertificateTypes}
          eventCertificateType={eventCertificateType}
        />
      )}
    </div>
  );
};

import CustomSelect from "../../../../forms/selects/CustomSelect";
import { Box, Grid } from "@material-ui/core";
import { SelectData } from "../../../../forms/selects/selectData";
import { useFormikContext } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationMembershipData } from "../../../../../models/organizationMembershipData";

interface OrganizationProps {
  allUserOrganizations: OrganizationMembershipData[];
  invoiceOrganization: OrganizationMembershipData | null;
}

export const UserOrganizationsDropDown: React.FC<OrganizationProps> = ({
  allUserOrganizations,
  invoiceOrganization,
}) => {
  const organizations = allUserOrganizations;

  const [organization, setOrganization] = useState(invoiceOrganization);

  const { values, setFieldValue } = useFormikContext<String>();

  const { t } = useTranslation(["common"]);

  const getSelectedOrganization = (
    allOrganizations: OrganizationMembershipData[],
    name: string
  ) => {
    if (name.length === 0) {
      return null;
    }
    return allOrganizations.find((element) => {
      return element.organization_name === name;
    });
  };

  const saveOrganization = (value: string) => {
    let selectedOrganization = getSelectedOrganization(allUserOrganizations, value);
    if (selectedOrganization !== undefined) {
      setOrganization(selectedOrganization);
    }
    setFieldValue("id", selectedOrganization?.organization);
  };

  return (
    <Box
      key={"organizations"}
      style={{
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box style={{ maxWidth: "600px", minWidth: "400px", width: "50%" }}>
        <CustomSelect
          name="id"
          id="Organization"
          label={t("forms.tabs.organization", { ns: "common" })}
          labelId="OrganizationID"
          value={organization ? organization.organization_name : ""}
          onChange={(event: React.ChangeEvent<{ name?: string; value: any }>) => {
            saveOrganization(event.target.value);
          }}
          options={
            organizations.map((x) => {
              return {
                key: x.organization_name,
                value: x.organization_name,
                label: x.organization_name,
              };
            }) as SelectData[]
          }
        />
      </Box>
    </Box>
  );
};

import React, { useEffect } from "react";
import HeadingLumos from "../../../../theming/HeadingLumos";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Grid, Hidden, Link } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { AppState } from "../../../../../redux";
import Loader from "../../../../theming/loader/Loader";
import { getInvoicesByOrganizationId } from "../../../../../redux/organizationInvoices/action";
import { Utils } from "../../../../../services/utils";
import { OrganizationInvoiceData } from "../../../../../models/organizationInvoiceData";

interface InvoiceManagementProps {
  organizationId: string;
}

/**
 * Component for managing organization invoices.
 * @param organizationId - The id of the organization.
 */
export const InvoiceManagement: React.FC<InvoiceManagementProps> = (props) => {
  const { organizationId } = props;
  const { t } = useTranslation(["organization"]);
  const dispatch = useDispatch();
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const organizationInvoiceList = useSelector(
    (state: AppState) => state.organizationInvoice
  );
  const organizationInvoices = organizationInvoiceList.organizationInvoiceData;

  useEffect(() => {
    dispatch(getInvoicesByOrganizationId(organizationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  return (
    <>
      <Grid>
        <Grid item xs={1}>
          <HeadingLumos>
            {t("invoice.showInvoices", { ns: "organization" })}
          </HeadingLumos>
        </Grid>
        <Grid container spacing={0} className={"myinvoices-list-header"}>
          <Hidden mdDown>
            <Grid item xs={3}>
              {t("invoice.number", { ns: "organization" })}
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {t("invoice.date", { ns: "organization" })}
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {t("invoice.dueDate", { ns: "organization" })}
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {t("download", { ns: "common" })}
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      {organizationInvoiceList?.isLoading ? (
        <Loader />
      ) : organizationInvoices?.length === 0 ? (
        <Box
          component="span"
          display="flex"
          alignItems="center"
          style={{ height: "100px" }}
        >
          {t("none", { ns: "invoices" })}
        </Box>
      ) : (
        organizationInvoices?.map((invoice: OrganizationInvoiceData) => {
          return (
            <React.Fragment key={`invoice-${invoice.id}`}>
              <Grid container spacing={0} className={"myinvoices-list-item"}>
                <Hidden mdDown>
                  <Grid item xs={3}>
                    {invoice.invoice_number}
                  </Grid>
                </Hidden>
                <Hidden mdDown>
                  <Grid item xs={3} style={{ textAlign: "center" }}>
                    {Utils.formatDateToCetFormat(invoice.invoice_date)}
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "center" }}>
                    {Utils.formatDateToCetFormat(invoice.due_date)}
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "center" }}>
                    <Link
                      href={invoice.file_name}
                      download={invoice.file_name}
                      target={"_blank"}
                      className={"myinvoices-document-download-icon"}
                    >
                      {console.log(`invoices.file_name: ` + invoice.file_name)}
                      <GetAppIcon />
                    </Link>
                  </Grid>
                </Hidden>
              </Grid>
              <Divider className={"invoice-list-separator"} />
            </React.Fragment>
          );
        })
      )}
    </>
  );
};

export default InvoiceManagement;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CookieIcon from "@mui/icons-material/Cookie";

import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Cookies } from "../../../../services/cookies";
import CustomCheckbox from "../../../forms/CustomCheckbox";
import { defaultPalette } from "../../../theming/defaultTheme";

export const CookieConsent: React.FC = () => {
  const { t } = useTranslation(["cookies", "snackbars"]);
  let acceptedSystemCookies = Cookies.get("allow_system_cookies") === "true";
  let acceptedStatCookies = Cookies.get("allow_stats_cookies") === "true";
  const [showCookieConsent, setShowCookieConsent] =
    React.useState(!acceptedSystemCookies);

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="contained"
        id="cookie-consent"
        title="cookie-consent"
        onClick={() => setShowCookieConsent(true)}
        style={{
          position: "relative",
          left: "0",
          bottom: "8px",
          padding: "4px",
        }}
      >
        <CookieIcon style={{ color: defaultPalette.text?.secondary }} />
      </Button>
      <Dialog maxWidth={"lg"} fullWidth={true} open={showCookieConsent}>
        <Formik
          onSubmit={(data) => {
            Cookies.set("allow_system_cookies", data.systemcookies ? "true" : "false");
            Cookies.set("allow_stats_cookies", data.statistics ? "true" : "false");
            setShowCookieConsent(false);
          }}
          initialValues={{
            systemcookies: true,
            statistics: acceptedStatCookies,
          }}
        >
          {(formikProps) => {
            const handleAccept = () => {
              formikProps.submitForm();
            };

            const handleAcceptAll = () => {
              formikProps.setFieldValue("statistics", true);
              formikProps.submitForm();
            };
            // TODO check parts to be lowercased!
            return (
              <Form>
                <DialogTitle>{t("cookieTitle")}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t("dialogue", { ns: "cookies" })}
                  </DialogContentText>
                  <Box component="div" m={2}>
                    <Box component="div" py={2}>
                      <CustomCheckbox
                        disabled={true}
                        name={"systemcookies"}
                        label={
                          <>
                            <b style={{ color: "#777" }}>{t("systemCookies")}</b>{" "}
                            {t("systemCookiesDetail", { ns: "cookies" })}
                          </>
                        }
                      />
                    </Box>
                    <Box component="div" py={2}>
                      <CustomCheckbox
                        name={"statistics"}
                        label={
                          <>
                            <b>{t("statCookies", { ns: "cookies" })}</b>{" "}
                            {t("statCookiesDetail", { ns: "cookies" })}
                          </>
                        }
                      />
                    </Box>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    id="accept-cookies"
                    size="medium"
                    variant="contained"
                    color="secondary"
                    onClick={handleAccept}
                  >
                    {t("forms.buttons.acceptSelection", { ns: "common" })}
                  </Button>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    autoFocus
                    onClick={handleAcceptAll}
                  >
                    {t("forms.buttons.acceptAll", { ns: "common" })}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { TrainingType } from "../models/enums/trainingType.enum";
import { UserType } from "../models/enums/userType.enum";
import { AppState } from "../redux";

// Routing
import routes from "./routes";
import TaskRoutesWrapper from "../components/core/tasks/TaskRoutesWrapper";
import AdminRedirect from "./AdminRedirect";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
// Auth Pages
import { LoginPage } from "../pages/auth/login/LoginPage";
import { RegistrationPage } from "../pages/auth/registration/RegistrationPage";
import { RegistrationSuccessPage } from "../pages/auth/registration/RegistrationSuccessPage";
import { ForgotPasswordPage } from "../pages/auth/forgot-password/ForgotPasswordPage";
import { ResetPasswordPage } from "../pages/auth/reset-password/ResetPasswordPage";
import { AccountConfirmationPage } from "../pages/auth/registration/AccountConfirmationPage";
import { LinkConfirmationPage } from "../pages/auth/link-confirmation/LinkConfirmationPage";
// Core Pages
import { UserLectureListPage } from "../pages/core/lectures/UserLectureListPage";
import { UserELearningDetailPage } from "../pages/core/elearning/UserELearningDetailPage";
import { Pinboard } from "../pages/core/pinboard/Pinboard";
import { CheckoutPage } from "../pages/core/checkout/CheckoutPage";
import { WebAccessibilityPage } from "../pages/core/web-accessibility/WebAccessibility";
import { UserInvoicePage } from "../pages/core/invoice/UserInvoicePage";
import { AfterCheckoutPage } from "../pages/core/checkout/AfterCheckoutPage";
import { PayPalPage } from "../pages/core/payment/PayPalPage";
import { BillingFormPage } from "../pages/core/payment/BillingFormPage";
import { VideoAuthenticationPage } from "../pages/core/auth-capture/VideoAuthenticationPage";
import { DashboardPage } from "../pages/core/dashboard/DashboardPage";
import { EventListPage } from "../pages/core/event/EventListPage";
import { WaitingListResponsePage } from "../pages/core/waiting-list/WaitingListResponsePage";
import { FavoritesPage } from "../pages/core/favorites/FavoritesPage";
import { ActivityHistoryPage } from "../pages/core/activity-history/ActivityHistoryPage";
import { UserEventsPage } from "../pages/core/event/UserEventsPage";
import { UserLecturesDetailsPage } from "../pages/core/lectures/UserLecturesDetailsPage";
import { TemplatesPage } from "../pages/core/template/TemplatesPage";
import { LearningEventsOverviewPage } from "../pages/core/elearning/LearningEventsOverviewPage";
import { UserEventDetailsPage } from "../pages/core/event/UserEventDetailsPage";
import { ProfileSettingsWrapperPage } from "../pages/core/profile/ProfileSettingsWrapperPage";
import { CrudEventPage } from "../pages/core/event/CrudEventPage";
import { CrudEventTemplatePage } from "../pages/core/template/CrudEventTemplatePage";
import { ELearningContainerPage } from "../pages/core/elearning/ELearningContainerPage";
import { CrudTaskTemplatePage } from "../pages/core/task/CrudTaskTemplatePage";
import { OrganizationAdminPage } from "../pages/core/organization/OrganizationAdminPage";
import { OrganizationOverviewPage } from "../pages/core/organization/OrganizationOverviewPage";
import { MembershipConfirmationPage } from "../pages/core/organization/MembershipConfirmationPage";
import {
  LmsElearningViewPage,
  ScormPlayer,
} from "../pages/core/elearning/LmsElearningViewPage";
// Public Pages
import { GeneralTermsAndConditionsPage } from "../pages/general-terms/GeneralTermsAndConditionsPage";
import { PrivacyPolicyPage } from "../pages/general-terms/PrivacyPolicyPage";
import { LegalNoticePage } from "../pages/general-terms/LegalNoticePage";
import { ContactFormPage } from "../pages/core/contact/ContactFormPage";
// Error
import { NoMatchPage } from "../pages/error/NoMatchPage";
import { MaintenancePage } from "../pages/error/MaintenancePage";
// Components
import Cart from "../components/core/booking/Cart";

export interface CoreRoute {
  route: string;
  componentToRender: React.ReactNode;
  exact?: boolean;
}

export const commonRoutes: CoreRoute[] = [
  {
    route: routes.settings,
    componentToRender: (
      <>
        <ProfileSettingsWrapperPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.organization,
    componentToRender: (
      <>
        <OrganizationOverviewPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.organization_admin_id,
    componentToRender: (
      <>
        <OrganizationAdminPage />
      </>
    ),
    exact: true,
  },
];

export const checkoutRoutes: CoreRoute[] = [
  {
    route: routes.checkout,
    componentToRender: (
      <>
        <CheckoutPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.bill,
    componentToRender: (
      <>
        <BillingFormPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.afterCheckoutBill,
    componentToRender: (
      <>
        <AfterCheckoutPage paymentMethod="bill" />
      </>
    ),
    exact: true,
  },
  {
    route: routes.afterCheckoutPaypal,
    componentToRender: (
      <>
        <AfterCheckoutPage paymentMethod="paypal" />
      </>
    ),
    exact: true,
  },
  {
    route: routes.paypal,
    componentToRender: (
      <>
        <PayPalPage />
      </>
    ),
    exact: true,
  },
];
export const employeeRoutes: CoreRoute[] = [
  ...checkoutRoutes,
  {
    route: routes.event_create,
    componentToRender: (
      <>
        <CrudEventPage typeOfTrainingToBeCreated={TrainingType.DefaultEvent} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_create,
    componentToRender: (
      <>
        <CrudEventPage typeOfTrainingToBeCreated={TrainingType.ELearning} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.blended_learning_create,
    componentToRender: (
      <>
        <CrudEventPage typeOfTrainingToBeCreated={TrainingType.BlendedLearning} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.event_edit_id,
    componentToRender: (
      <>
        <CrudEventPage typeOfTrainingToBeCreated={TrainingType.DefaultEvent} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_edit_id,
    componentToRender: (
      <>
        <CrudEventPage typeOfTrainingToBeCreated={TrainingType.ELearning} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.blended_learning_edit_id,
    componentToRender: (
      <>
        <CrudEventPage typeOfTrainingToBeCreated={TrainingType.BlendedLearning} />
      </>
    ),
    exact: true,
  },
  {
    route: routes.tasks,
    componentToRender: (
      <>
        <TaskRoutesWrapper />
      </>
    ),
    exact: false,
  },
  {
    route: routes.templates,
    componentToRender: (
      <>
        <TemplatesPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.templates_new_event,
    componentToRender: (
      <>
        <CrudEventTemplatePage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.templates_edit_event_id,
    componentToRender: (
      <>
        <CrudEventTemplatePage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.templates_new_task,
    componentToRender: (
      <>
        <CrudTaskTemplatePage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.templates_edit_task_id,
    componentToRender: (
      <>
        <CrudTaskTemplatePage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.activityHistory,
    componentToRender: (
      <>
        <ActivityHistoryPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.checkout,
    componentToRender: (
      <>
        <CheckoutPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.pinboard_staff_elearning,
    componentToRender: (
      <>
        <Pinboard />
      </>
    ),
    exact: true,
  },
  {
    route: routes.pinboard_staff_blended_learning,
    componentToRender: (
      <>
        <Pinboard />
      </>
    ),
    exact: true,
  },
];

export const testParticipantRoutes: CoreRoute[] = [
  {
    route: routes.my_events,
    componentToRender: (
      <>
        <UserEventsPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_events_details_event_id,
    componentToRender: (
      <>
        <UserEventDetailsPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_events_details_elearning_id,
    componentToRender: (
      <>
        <UserELearningDetailPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.pinboard_participant,
    componentToRender: (
      <>
        <Pinboard />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_dashboard,
    componentToRender: (
      <>
        <DashboardPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_lms,
    componentToRender: (
      <>
        <LmsElearningViewPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_invoices,
    componentToRender: (
      <>
        <UserInvoicePage />
      </>
    ),
    exact: true,
  },
];

export const participantRoutes: CoreRoute[] = [
  ...testParticipantRoutes,
  ...checkoutRoutes,
  {
    route: routes.events,
    componentToRender: (
      <>
        <EventListPage />
        <Cart />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_authentication_id,
    componentToRender: (
      <>
        <VideoAuthenticationPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_test_id,
    componentToRender: (
      <>
        <ELearningContainerPage />
      </>
    ),
    exact: true,
  },

  {
    route: routes.waiting_list_response,
    componentToRender: (
      <>
        <WaitingListResponsePage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.favorites,
    componentToRender: (
      <>
        <FavoritesPage />
        <Cart />
      </>
    ),
    exact: true,
  },
  {
    route: routes.link_confirmation,
    componentToRender: (
      <>
        <LinkConfirmationPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_invoices,
    componentToRender: (
      <>
        <UserInvoicePage />
      </>
    ),
    exact: true,
  },
];

export const speakerRoutes: CoreRoute[] = [
  {
    route: routes.my_lectures,
    componentToRender: (
      <>
        <UserLectureListPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_lectures_details_event_id,
    componentToRender: (
      <>
        <UserLecturesDetailsPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.my_lectures_details_elearning_id,
    componentToRender: (
      <>
        <UserELearningDetailPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.elearning_lms,
    componentToRender: (
      <>
        <LmsElearningViewPage />
      </>
    ),
    exact: true,
  },
  {
    route: routes.pinboard_speaker,
    componentToRender: (
      <>
        <Pinboard />
      </>
    ),
    exact: true,
  },
];

export const adminRoute: CoreRoute[] = [
  {
    route: routes.admin,
    componentToRender: <AdminRedirect />,
  },
];

/**
 * CoreRoutes component handles routing for different user roles and authentication states.
 * It dynamically renders routes based on the current user's role.
 */
const CoreRoutes: React.FC = () => {
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const undefinedRoleRoutes = [
    ...commonRoutes,
    ...employeeRoutes,
    ...participantRoutes,
    ...speakerRoutes,
    ...adminRoute,
  ]; // use all routes if not logged in, to trigger the redirect of private routes

  /**
   * Determines the routes to render based on the current user's role.
   * @returns An array of CoreRoute objects representing the routes to render.
   */
  function getRoutesByRole() {
    switch (currentUser?.user_type) {
      case UserType.Undefined:
        return undefinedRoleRoutes;
      case UserType.Admin:
        return [...adminRoute];
      case UserType.Speaker:
        return [...commonRoutes, ...speakerRoutes];
      case UserType.Participant:
        return [...commonRoutes, ...participantRoutes];
      case UserType.Employee:
      case UserType.Apprentice:
        return [...commonRoutes, ...employeeRoutes];
      case UserType.TestParticipant:
        return [...commonRoutes, ...testParticipantRoutes];
      default:
        return undefinedRoleRoutes;
    }
  }

  return (
    <Switch>
      {/* Auth Routes */}
      <AuthRoute path={routes.login} exact>
        <LoginPage />
      </AuthRoute>
      <AuthRoute path={routes.forgotPassword} exact>
        <ForgotPasswordPage />
      </AuthRoute>
      <AuthRoute path={routes.resetPassword}>
        <ResetPasswordPage />
      </AuthRoute>
      <AuthRoute path={routes.registration} exact>
        <RegistrationPage />
      </AuthRoute>
      <AuthRoute path={routes.activate_account} exact>
        <AccountConfirmationPage />
      </AuthRoute>
      <AuthRoute path={routes.register_success} exact>
        <RegistrationSuccessPage />
      </AuthRoute>

      {/* Home Route Redirect */}
      <Route
        path={routes.home}
        exact
        render={() => (
          <Redirect
            to={currentUser?.user_type === UserType.Admin ? routes.admin : routes.events}
          />
        )}
      />
      {/* Events Route Redirect */}
      <Route
        path={routes.events}
        exact
        render={() =>
          currentUser?.user_type === UserType.Admin ? (
            <Redirect to={routes.admin} />
          ) : (
            <>
              <EventListPage />
              {currentUser &&
                [UserType.Participant, UserType.Employee, UserType.Apprentice].includes(
                  currentUser.user_type
                ) && <Cart />}
            </>
          )
        }
      />
      <Route exact path={routes.booking}>
        <Redirect to={routes.checkout} />
      </Route>
      <Route exact path={routes.learning_events_overview}>
        <LearningEventsOverviewPage />
      </Route>
      <Route exact path={routes.privacy_policy} component={PrivacyPolicyPage} />
      <Route exact path={routes.contact} component={ContactFormPage} />
      <Route exact path={routes.legal_notice} component={LegalNoticePage} />
      <Route
        exact
        path={routes.general_terms_and_conditions}
        component={GeneralTermsAndConditionsPage}
      />
      <Route path={routes.activate_organization_membership} exact>
        <MembershipConfirmationPage />
      </Route>
      <Route exact path={routes.web_accessibility} component={WebAccessibilityPage} />
      {/* <Route exact path={routes.testUI} component={TestUIComponent} /> */}
      {getRoutesByRole().map((item: CoreRoute) => (
        <PrivateRoute
          key={item.route}
          path={item.route}
          exact={item.exact}
          children={item.componentToRender}
        />
      ))}
      <Route exact path={routes.maintenance} component={MaintenancePage} />
      <Route exact path={routes.scorm_player_id}>
        <ScormPlayer />
      </Route>
      {/* 404 Not Found Route */}
      <Route component={NoMatchPage} />
    </Switch>
  );
};

export default CoreRoutes;

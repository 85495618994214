import { Breadcrumbs, Container, Link, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import {
  LumosArrowSvg,
  LumosHomeSvg,
} from "../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { MonoModeProps } from "../../../../../models/accessibility";
import { AppState } from "../../../../../redux";
import { Utils } from "../../../../../services/utils";
import routes from "../../../../../routing/routes";
import { BaseNavData, getAllRoutes, NavigationData } from "../navigationData";
import { BreadcrumbsService } from "./breadcrumbsService";
import { useTranslation } from "react-i18next";
import { defaultPalette } from "../../../../theming/defaultTheme";

const useStyles = makeStyles({
  root: {
    marginTop: "2px",
    backgroundColor: (props: MonoModeProps) =>
      props.monoMode ? "#fff" : "var(--secondary-accent-2)",
    borderBottom: (props: MonoModeProps) => (props.monoMode ? "1px solid #000" : "none"),
    padding: "20px 0",
  },
  svgIcon: {
    "&:hover": {
      stroke: "var(--primary-color)",
    },
  },
});

const BreadcrumbsLumos: React.FC = () => {
  const { t } = useTranslation(["navigationData", "snackbars"]);
  const location = useLocation();
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const [breadcrumbs, setBreadcrumbs] = useState<NavigationData[]>([]);

  // use useCallback hook to make sure the function isn't called on every render in useEffect hook
  const getBreadcrumbs = useCallback(() => {
    const navigationData = getAllRoutes();

    const breadcrumbsService = new BreadcrumbsService();
    const subPaths = breadcrumbsService.splitIntoSubpaths(location.pathname);

    const breadcrumbs = breadcrumbsService.getBreadCrumbs(subPaths, navigationData);

    return breadcrumbs;
  }, [location.pathname]);

  // calc breadcrumbs everytime the location has changed
  useEffect(() => {
    setBreadcrumbs(getBreadcrumbs());
  }, [location.pathname, getBreadcrumbs]);

  const classes = useStyles({ monoMode: accessibility.monoMode });
  const [isHovered, setIsHovered] = useState(false); // set the hovered state of the home link to style the element manually

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Breadcrumbs separator={<LumosArrowSvg />} aria-label="breadcrumb">
            <Link
              component={RouterLink}
              to={routes.events}
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            >
              <LumosHomeSvg
                fill={
                  isHovered
                    ? accessibility.monoMode
                      ? "red"
                      : defaultPalette.secondary?.main
                    : defaultPalette.primary?.main
                }
              />
            </Link>
            {breadcrumbs.map((breadcrumbItem: BaseNavData, index: number) => {
              const routeHasIdParamInBetween =
                breadcrumbItem.route.includes(":id") &&
                breadcrumbItem.route.lastIndexOf(":id") < breadcrumbItem.route.length;

              const breadcrumbLink = routeHasIdParamInBetween
                ? Utils.getCurrentPathWithoutLastSubPath(location.pathname)
                : breadcrumbItem.route;

              return breadcrumbs.length - 1 === index ? (
                <Typography color="textPrimary" key={index}>
                  {t(breadcrumbItem.text, { ns: "navigationData" })}
                </Typography>
              ) : (
                <Link component={RouterLink} to={breadcrumbLink} key={index}>
                  {t(breadcrumbItem.text, { ns: "navigationData" })}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Container>
      </div>
    </>
  );
};

export default BreadcrumbsLumos;

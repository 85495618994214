import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";
import { useSelector } from "react-redux";
import { UserType } from "../../../../../../models/enums/userType.enum";
import {
  PinboardCommentFormData,
  PinboardFormData,
} from "../../../../../../models/pinboardData";
import { AppState } from "../../../../../../redux";
import useEmojis from "../../../../../hooks/useEmojis";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import { useState } from "react";
import { EditorState } from "draft-js";

export interface IPinboardEditorProps {
  error: string;
  isFirstPostOfTopic: boolean;
  handleChange: (text: EditorState) => void;
  values: PinboardFormData | PinboardCommentFormData;
}

const useStyles = makeStyles({
  invalidRoot: {
    border: "none",
    borderBottom: "2px solid red",
  },
  invalid: {
    backgroundColor: "var(--red-accent-3)",
  },
  validRoot: {
    border: "none",
    borderBottom: "2px solid #a2a2a2",
  },
});

const PinboardEditor: React.FunctionComponent<IPinboardEditorProps> = (props) => {
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const { emojis, emojiTitle, faces, controls, emojiControls } = useEmojis();
  const classes = useStyles();
  const [emojiTip, setEmojiTip] = useState(false);

  return (
    <>
      <MUIRichTextEditor
        classes={{
          root: props.error !== "" ? classes.invalidRoot : classes.validRoot,
          editor: props.error !== "" ? classes.invalid : undefined,
        }}
        onChange={props.handleChange}
        defaultValue={
          props.isFirstPostOfTopic
            ? (props.values as PinboardFormData).post
            : (props.values as PinboardCommentFormData).answer
        }
        label="Beitrag verfassen"
        autocomplete={{
          strategies: [
            {
              items: emojis,
              triggerChar: ":",
            },
          ],
        }}
        toolbarButtonSize="small"
        controls={
          currentUser &&
          [UserType.Employee, UserType.Apprentice].includes(currentUser.user_type)
            ? controls
            : controls.filter((controlName) => controlName !== "link")
        }
        customControls={[
          {
            name: "indentLine",
            icon: <FormatIndentIncreaseIcon />,
            type: "inline",
            inlineStyle: {
              marginLeft: "20px",
            },
          },
          ...emojiControls,
          {
            name: "emojisHint",
            icon: (
              <Tooltip title={emojiTitle} arrow>
                <small>
                  <b style={{ padding: ".1em" }}> : ) </b>
                </small>
              </Tooltip>
            ),
            type: "callback",
            onClick: () => setEmojiTip(!emojiTip),
          },
        ]}
        draftEditorProps={{
          spellCheck: true,
          stripPastedStyles: true,
        }}
      />
      <Dialog open={emojiTip} maxWidth={"xs"}>
        <DialogTitle>Emojis</DialogTitle>
        <DialogContent>
          <Typography>{emojiTitle + " Alternativen:"}</Typography>
          {faces.map((face, index) => {
            return <p key={index}>{face}</p>;
          })}
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => setEmojiTip(false)}
            variant="contained"
            color="secondary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PinboardEditor;

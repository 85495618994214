import React from "react";
import { Grid } from "@material-ui/core";
import CustomSelect from "../../../../forms/selects/CustomSelect";
import { useTranslation } from "react-i18next";

interface PaymentOptionDropDownParams {
  value: string;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  orderIsFreeOfCharge: boolean;
  userHasNoOrganizations: boolean;
  grossPrice: number;
  giropayIsAvailable: boolean;
}

export const PaymentOptionDropDown: React.FC<PaymentOptionDropDownParams> = (
  props: PaymentOptionDropDownParams
) => {
  const { t } = useTranslation(["booking", "snackbars"]);

  var paymentOptions = [];

  const paypalLabel = props.giropayIsAvailable
    ? "paymentOptions.methodPayPal"
    : "paymentOptions.methodPayPalNoGiropay";

  if (props.orderIsFreeOfCharge || !props.userHasNoOrganizations) {
    paymentOptions.push({
      key: "Rechnung",
      value: "Rechnung",
      label: t("paymentOptions.methodInvoice", { ns: "booking" }),
    });
  }

  if (!props.orderIsFreeOfCharge && props.grossPrice >= 0.01) {
    paymentOptions.push({
      key: "PayPal",
      value: "PayPal",
      label: t(paypalLabel, { ns: "booking" }),
    });
  }
  return (
    <Grid item xs={12} sm={6} style={{ padding: "80px 0 40px 0" }}>
      <CustomSelect
        name="payment_method"
        id="payment_method"
        labelId={"payment_method_label"}
        label={t("paymentOptions.paymentMethod", { ns: "booking" })}
        value={props.value}
        onChange={props.onChange}
        options={paymentOptions}
        required={true}
      />
    </Grid>
  );
};

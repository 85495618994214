import {
  faEnvelope,
  faMinusCircle,
  faSeedling,
  faSlash,
  faStopwatch,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Grid, Tooltip } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CateringType } from "../../../../../models/enums/cateringType";
import { mapCountToProgressStatus } from "../../../../../models/enums/elearningProgressStatus.enum";
import {
  ELearningParticipationStatus,
  ParticipationStatus,
} from "../../../../../models/enums/participationStatus.enum";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { UserType } from "../../../../../models/enums/userType.enum";
import { ParticipantListData } from "../../../../../models/participantData";
import { AppState } from "../../../../../redux";
import { showDialog } from "../../../../../redux/dialogs/actions";
import { cancelReactivated } from "../../../../../redux/participants/actions";
import styles from "../../../../../styles/custom-styles.module.scss";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import Loader from "../../../../theming/loader/Loader";
import "./Participants.scss";
import { ParticipantsStatus } from "./ParticipantsStatus";

interface ParticipantsItemProps {
  participant: ParticipantListData;
  disabledSelect?: boolean;
  index: number;
  trainingType: TrainingType;
  checkedHandler?: () => void;
  checked: boolean;
}

export const ParticipantsItem: React.FC<ParticipantsItemProps> = (props) => {
  const dispatch = useDispatch();
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const isCancelled =
    props.participant.status === ParticipationStatus.Cancelled ||
    props.participant.status === ParticipationStatus.CancelledByStaff;
  const isWaitingList =
    props.participant.status === ParticipationStatus.WaitingList ||
    props.participant.status === ParticipationStatus.ReservedCart;

  const inactivate = () => {
    dispatch(
      showDialog({
        title: `E-Learning-Teilnahme für ${props.participant.user.fullname} inaktivieren`,
        message: `Möchen Sie den E-Learning-Bearbeitungszeitraum für ${props.participant.user.fullname} beenden?`,
        action: () => {
          dispatch(
            cancelReactivated(props.participant.event.toString(), props.participant.id)
          );
        },
      })
    );
  };
  const progress = mapCountToProgressStatus(
    props.participant.elearning_started_count,
    props.participant.elearning_status === ELearningParticipationStatus.Passed,
    props.participant.status === ParticipationStatus.CertificateRevoked
  );

  return (
    <Grid
      container
      className={"participant-list-item"}
      style={{ display: "flex", alignItems: "center", border: "none" }}
    >
      {props.participant.changeStatus ? (
        <Loader height={63} />
      ) : (
        <>
          <Grid
            item
            xs={8}
            sm={props.participant.status === ParticipationStatus.ReservedCart ? 5 : 8}
          >
            <Grid container>
              <Grid
                item
                xs={2}
                sm={
                  props.participant.status === ParticipationStatus.ReservedCart ? 3 : 2
                }
              >
                <CustomCheckbox
                  name={String(props.participant.id)}
                  onChange={props.checkedHandler}
                  checked={props.checked}
                />
              </Grid>
              {props.participant.reactivated && (
                <Grid item xs={1}>
                  <Box className="participant-reactivated">
                    <Tooltip
                      placement="right"
                      title={`Die reaktivierte E-Learning-Teilnahme von ${
                        props.participant.user.fullname
                      } wird am ${new Date(
                        props.participant.overall_end
                      ).toLocaleDateString()} automatisch wieder inaktiv.`}
                      arrow
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={faStopwatch}
                          size="lg"
                          color={accessibility.monoMode ? "#000" : styles.orange}
                        />
                      </span>
                    </Tooltip>
                    <Tooltip
                      placement="right"
                      title={`E-Learning-Teilnahme jetzt inaktivieren`}
                      arrow
                    >
                      <span style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          size="lg"
                          color={accessibility.monoMode ? "#000" : styles.primary}
                          onClick={inactivate}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
              )}
              <Grid
                item
                xs={8}
                sm={4}
                style={{
                  paddingLeft:
                    props.participant.status === ParticipationStatus.ReservedCart
                      ? "7px"
                      : "0px",
                }}
              >
                {`${props.participant.user.last_name}, ${props.participant.user.first_name}`}
                {props.participant.user.academic_title != null
                  ? `, ${props.participant.user.academic_title}`
                  : ""}
                <br />
                <Box
                  component={"span"}
                  className={"participant-list-item-secondarytext"}
                >
                  {props.participant.user.email}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            {props.participant.catering_option !== CateringType.Undefined &&
              currentUser?.user_type !== UserType.Speaker &&
              (props.participant.catering_option === CateringType.NoMeal ? (
                <Box component={"span"} style={{ position: "relative" }}>
                  <FontAwesomeIcon
                    icon={faUtensils}
                    size={"2x"}
                    color={"var(--text-color)"}
                  />
                  <FontAwesomeIcon
                    icon={faSlash}
                    style={{
                      position: "absolute",
                      left: "-6px",
                      top: "-12px",
                    }}
                    color={"#f44336"}
                    size={"2x"}
                  />
                </Box>
              ) : props.participant.catering_option === CateringType.DefaultMeal ? (
                <FontAwesomeIcon
                  icon={faUtensils}
                  size={"2x"}
                  color={"var(--text-color)"}
                />
              ) : (
                props.participant.catering_option === CateringType.Vegetarian && (
                  <FontAwesomeIcon icon={faSeedling} size={"2x"} color={"#95e917"} />
                )
              ))}
          </Grid>
          {props.trainingType === TrainingType.ELearning && (
            <>
              <Grid item xs={12} sm={3}>
                <ParticipantsStatus
                  participant={props.participant}
                  index={props.index}
                  trainingType={TrainingType.ELearning}
                />
              </Grid>
            </>
          )}
          {props.trainingType === TrainingType.BlendedLearning && (
            <>
              <Grid item xs={12} sm={3}>
                <ParticipantsStatus
                  participant={props.participant}
                  index={props.index}
                  trainingType={TrainingType.DefaultEvent}
                  isPartOfBlendedLearning={true}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                {!isCancelled && !isWaitingList && (
                  <ParticipantsStatus
                    participant={props.participant}
                    index={props.index}
                    trainingType={TrainingType.ELearning}
                    isPartOfBlendedLearning={true}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box display={"flex"} justifyContent={"center"}>
                  <Box
                    p={1}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <strong>
                      <span>{progress}</span>
                    </strong>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
          {props.trainingType === TrainingType.DefaultEvent && (
            <>
              {props.participant.status === ParticipationStatus.ReservedCart && (
                <Grid item xs={12} sm={3}>
                  {"Teilnahme angefragt"}
                  {(new Date(props.participant.overall_end).getTime() -
                    new Date(props.participant.last_changed_on).getTime()) /
                    (1000 * 60 * 60 * 24) >
                  3 ? (
                    <Tooltip
                      arrow
                      placement="right"
                      enterDelay={500}
                      leaveDelay={200}
                      style={{ fontSize: "16px" }}
                      title={
                        "Die Anzahl der Tage, in welcher der Nutzer auf die automatische Anfrage reagieren kann"
                      }
                    >
                      <Badge
                        badgeContent={
                          3 -
                          Math.round(
                            (Date.now() -
                              new Date(props.participant.last_changed_on).getTime()) /
                              (1000 * 60 * 60 * 24)
                          )
                        }
                        color="primary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        overlap="circular"
                      >
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          size={"lg"}
                          color={
                            accessibility.monoMode ? "#000" : "var(--primary-color)"
                          }
                          style={{
                            marginBottom: "10px",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        />
                      </Badge>
                    </Tooltip>
                  ) : (
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size={"lg"}
                      color={accessibility.monoMode ? "#000" : "var(--primary-color)"}
                      style={{
                        marginBottom: "10px",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12} sm={3}>
                <ParticipantsStatus
                  participant={props.participant}
                  index={props.index}
                  trainingType={TrainingType.DefaultEvent}
                />
              </Grid>
            </>
          )}
          <Grid
            container
            className={"participant-list-item"}
            style={{
              width: "100%",
            }}
          />
        </>
      )}
    </Grid>
  );
};

// TODO no translation yet

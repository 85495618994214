import { Box, CircularProgress } from "@material-ui/core";
import * as React from "react";
import "./Loader.scss";

interface LoaderProps {
  height?: number;
  loadingText?: string;
}

const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <div
      className="loader"
      style={{
        height: props.height ? props.height.toLocaleString() + "px" : "300px",
        margin: "auto",
      }}
    >
      <Box
        component={"div"}
        className={"loader-wrapper"}
        style={{
          height: props.height ? props.height.toLocaleString() + "px" : "300px",
        }}
      >
        <CircularProgress
          style={props.loadingText ? { marginRight: "10px" } : undefined}
        />{" "}
        {props.loadingText}
      </Box>
    </div>
  );
};

export default Loader;

import { Box } from "@material-ui/core";
import * as React from "react";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";

interface EventItemStatusTextProps {
  status: EventStatus;
  slots: number;
  slots_taken: number;
  eventWorkload: number;
  waiting_list: number;
}

const EventItemStatusText: React.FunctionComponent<EventItemStatusTextProps> = (
  props
) => {
  const freeSlots = props.slots - props.slots_taken;
  return (
    <Box style={{ height: "17px" }}>
      {props.eventWorkload >= 80 &&
        props.eventWorkload < 100 &&
        props.status !== EventStatus.Full && (
          <>
            {"(" +
              freeSlots +
              (freeSlots === 1 ? " freier Platz" : " freie Plätze") +
              ")"}
          </>
        )}
      {(props.eventWorkload === 100 || props.status === EventStatus.Full) && (
        <> {"(" + props.waiting_list + " auf der Warteliste)"}</>
      )}
    </Box> //to match the height of the text above
  );
};

export default EventItemStatusText;

// TODO no translation yet

import React from "react";
import { useEffect } from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { CheckoutButtons } from "../../../components/core/booking/checkout/checkoutButtons/CheckoutButtons";
import { PaypalButton } from "../../../components/core/booking/checkout/Paypal/PaypalButton";
import { AppState } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/theming/loader/Loader";
import { setPaymentMethod } from "../../../redux/billingAddress/actions";
import { useTranslation } from "react-i18next";
import { PAYMENT_METHOD_CARD_ID, PAYMENT_METHOD_GIROPAY_ID } from "../../../constants";
import { Utils } from "../../../services/utils";
import {
  getPaymentMethods,
  setPaymentMethodsLoaded,
} from "../../../redux/paymentMethod/actions";

const paypal_client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID ?? "";

export const PayPalPage: React.FC = () => {
  const { t } = useTranslation(["booking", "common", "snackbars"]);

  const dispatch = useDispatch();

  // If option 'debit or credit card' is chosen change payment method
  const setCardPayment = () => {
    dispatch(setPaymentMethod("Karte"));
  };
  // If option 'giropay' is chosen change payment method
  const setGiropayPayment = () => {
    dispatch(setPaymentMethod("Giropay"));
  };

  const cartState = useSelector((appState: AppState) => appState.cart);
  const paymentMethodState = useSelector(
    (appState: AppState) => appState.paymentMethods
  );
  const paypalOrderState = useSelector((appState: AppState) => appState.paypal);

  const grossPrice: number = cartState.cart.gross_total / 100 || 0;
  const orderState = useSelector((appState: AppState) => appState.order);

  let cardIsAvailable: boolean = false;
  let giropayIsAvailable: boolean = false;

  useEffect(() => {
    if (!paymentMethodState.isLoading && !paymentMethodState.paymentMethodsLoaded) {
      dispatch(getPaymentMethods());
      dispatch(setPaymentMethodsLoaded());
    }
  }, [
    dispatch,
    paymentMethodState,
    paymentMethodState.paymentMethodsLoaded,
    paymentMethodState.paymentMethods,
  ]);

  if (paymentMethodState.paymentMethodsLoaded) {
    let giropay = null;
    giropay = Utils.getPaymentMethod(
      paymentMethodState.paymentMethods,
      PAYMENT_METHOD_GIROPAY_ID
    );
    giropayIsAvailable = giropay ? giropay.is_available : false;

    let card = null;
    card = Utils.getPaymentMethod(
      paymentMethodState.paymentMethods,
      PAYMENT_METHOD_CARD_ID
    );
    cardIsAvailable = card ? card.is_available : false;
  }

  if (
    paypalOrderState.isSending ||
    paymentMethodState.isLoading ||
    !paymentMethodState.paymentMethodsLoaded
  ) {
    return <Loader loadingText={t("waitNote", { ns: "common" })} />;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
        gap: "30px",
        width: "100%",
      }}
    >
      <span>{t("paypalNote", { ns: "booking" })}</span>
      <span>{t("paypalWarning", { ns: "booking" })}</span>
      <div
        className="paypal-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
          minWidth: "20%",
        }}
      >
        <PayPalScriptProvider
          options={{
            "client-id": paypal_client_id,
            currency: "EUR",
            "enable-funding": "paylater",
            "disable-funding": "sepa",
            components: "buttons",
            locale: "de_DE",
          }}
        >
          <PaypalButton funding={"paypal"} orderData={orderState.orderData} />
          <PaypalButton funding={"paylater"} orderData={orderState.orderData} />
          {cardIsAvailable && (
            <PaypalButton
              funding={"card"}
              onClick={setCardPayment}
              orderData={orderState.orderData}
            />
          )}
          {giropayIsAvailable && grossPrice >= 1.0 && (
            <PaypalButton
              funding={"giropay"}
              onClick={setGiropayPayment}
              orderData={orderState.orderData}
            />
          )}
        </PayPalScriptProvider>
      </div>
      <CheckoutButtons isPaypal={true} isLoading={false} />
    </div>
  );
};

// TODO locale in buttons dependent on current language

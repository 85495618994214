import { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Camera, PlayArrow } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DocumentType } from "../../../../../models/documentData";
import { AppState } from "../../../../../redux";
import {
  addDocumentCapture,
  addImageCapture as addParticipantCapture,
  setDocumentCaptureRect,
  setParticipantCaptureRect,
} from "../../../../../redux/videoAuthentication/actions";
import CustomButton from "../../../../theming/CustomButton";
import example1 from "../../../../../assets/video_authentisierung/authentifizierung-1.png";
import example2 from "../../../../../assets/video_authentisierung/authentifizierung-2.png";
import {
  residencePermitPhotoDimensions,
  passPhotoDimensions,
  cardIdPhotoDimensions,
  cardIdDimensions,
} from "../../../../../utils/captureDimentions";
import { CaptureIdCardList, CaptureList } from "./CaptureList";
import { CountDown } from "./CountDown";
import { NavControls } from "./NavControls";
import CaptureHints from "./CaptureHints";
import OnSiteAuthPopup from "./on-site-popup/OnSiteAuthPopup";
import { loggerService } from "../../../../../api";
import { LogLevelType } from "../../../../../models/enums/logLevelType.enum";

/**
 * Renders the Capture component.
 * This component handles capturing images from the camera and provides UI for the capture process.
 * @returns JSX element
 */
export function Capture() {
  const { t } = useTranslation(["authCapture", "snackbars"]);
  // Refs for canvas and video elements
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(document.createElement("video"));

  // Redux state and dispatch
  const { captures } = useSelector((state: AppState) => state.videoAuthentication);
  const { documentCaptures } = useSelector(
    (state: AppState) => state.videoAuthentication
  );
  const { metaData } = useSelector((state: AppState) => state.videoAuthentication);
  const dispatch = useDispatch();

  // Local state
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
  const [timerStarted, setTimerStarted] = useState<boolean>(false);
  const [modeCardIDCapture, setModeCardIDCapture] = useState<boolean>(false);

  // Other hooks
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const componentName = "Capture";

  /**
   * Calculates the countdown duration for image capture.
   *
   * @returns {number} - The countdown duration in seconds.
   */
  const contdownToCapture = () =>
    process.env.REACT_APP_COUNTDOWN_AUTH_VIDEOCAPTURE
      ? Number(process.env.REACT_APP_COUNTDOWN_AUTH_VIDEOCAPTURE)
      : 10;

  /**
   * Represents the rectangle used for capturing images.
   *
   * @typedef {Object} CaptureRectangle
   * @property {number} x - The x-coordinate of the top-left corner of the rectangle.
   * @property {number} y - The y-coordinate of the top-left corner of the rectangle.
   * @property {number} width - The width of the rectangle.
   * @property {number} height - The height of the rectangle.
   *
   * @type {CaptureRectangle | undefined}
   */
  let currentRect: { x: number; y: number; width: number; height: number } | undefined =
    undefined;

  /**
   * Routes to the next step and stops all video-related processes.
   */
  const routeToNextStep = () => {
    stopVideoAndCameraStream();
    loggerService.addLog(`${componentName} - routeToNextStep`, LogLevelType.Info);
    history.push("?step=2");
  };
  /**
   * Routes back to the previous step and stops all video-related processes.
   */
  const routeBack = () => {
    stopVideoAndCameraStream();
    loggerService.addLog(`${componentName} - routeBack`, LogLevelType.Info);
    history.push("?");
  };

  /**
   * Function to get dimensions for document photo based on type
   * @param {DocumentType} type - The type of document.
   * @returns {Object} - Dimensions of the document photo.
   */
  const getDocumentPhotoRectByType = (type: DocumentType) => {
    switch (type) {
      case DocumentType.ResidencePermit:
        return residencePermitPhotoDimensions;
      case DocumentType.Passport:
        return passPhotoDimensions;
      case DocumentType.IdentityCard:
        return cardIdPhotoDimensions;
      default:
        throw new Error("Unknow photo type");
    }
  };

  // Focuse at the top of the page, if side is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Request camera stream on component load
  useEffect(() => {
    if (videoRef.current !== null) {
      requestCameraStream();
    }
    return () => {
      stopCameraStream();
    };
  }, [videoRef.current]);

  // Attach camera stream to video element
  useEffect(() => {
    const video = videoRef.current;

    if (video === null) {
      return;
    }

    video.srcObject = cameraStream;

    if (cameraStream) {
      video.play();
    }
    return () => {
      stopVideo();
    };
  }, [cameraStream]);

  // Attach video to canvas
  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (cameraStream && video && ctx && canvas && !video.paused && !video.ended) {
      const [start, stop] = drawMaskedVideoFrame(video, canvas, ctx);
      start();
      return stop;
    }
  }, [videoRef, canvasRef, cameraStream, modeCardIDCapture]);

  /**
   * Function to draw with participant mask
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @param {HTMLVideoElement} video - The video element.
   * @param {HTMLCanvasElement} canvas - The canvas element.
   */
  function drawWithParticipantMask(
    ctx: CanvasRenderingContext2D,
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement
  ) {
    //Blurred Background
    ctx.filter = "blur(20px) opacity(35%)";
    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);
    ctx.drawImage(video, 0, 0);
    ctx.beginPath();

    //MASKS
    const { xCenter, hFace, wFace } = getFaceMaskDimensions(canvas.width, canvas.height);

    const radiusX = wFace / 2;
    const x = xCenter;
    const radiusY = hFace / 2;
    const y = radiusY + 20;

    const { top, right, bottom, left } = getMaskDocumentRect(x, y, wFace);

    if (currentRect === undefined) {
      currentRect = { x: x, y: y, width: radiusX * 2, height: radiusY * 2 };
      dispatch(setParticipantCaptureRect(currentRect));
    }

    ctx.ellipse(x, y, radiusX, radiusY, 0, 0, 2 * Math.PI);
    ctx.rect(x - radiusX, y - radiusY, radiusX * 2, radiusY * 2);

    ctx.rect(left, top, right - left, bottom - top);
    ctx.clip();

    //CLEAR Image
    ctx.filter = "none";

    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);
    ctx.drawImage(video, 0, 0);
    ctx.strokeStyle = process.env.REACT_APP_PRIMARY_COLOR ?? "#6db4bb";
    ctx.stroke();
  }

  /**
   * Function to draw masked video frame
   * @param {HTMLVideoElement} video - The video element.
   * @param {HTMLCanvasElement} canvas - The canvas element.
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @returns {Array<Function>} - Start and stop functions for animation.
   */
  function drawMaskedVideoFrame(
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) {
    let requestId: any;

    const animate = () => {
      if (video.videoWidth * video.videoHeight > 0) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        if (modeCardIDCapture) {
          drawWithDocumentMask(ctx, video, canvas);
        } else {
          drawWithParticipantMask(ctx, video, canvas);
        }
      }
      start();
    };

    const start = () => {
      requestId = requestAnimationFrame(animate);
    };

    const stop = () => {
      if (requestId) {
        cancelAnimationFrame(requestId);
        requestId = undefined;
      }
    };

    return [start, stop];
  }

  /**
   * Function to display AusweisIDent alternative information
   */
  // TODO add AusweisIDent
  // function displayAusweisIDentInformation() {
  //   enqueueSnackbar(
  //     "“Eine Authentifizierung ist offenbar aktuell nicht möglich. Sie können sich alternativ mit dem AusweisIDent Verfahren authentifizieren”",
  //     {
  //       variant: "error",
  //     }
  //   );
  // }

  /**
   * Function to get face mask dimensions
   * @param {number} canvasWidth - The width of the canvas.
   * @param {number} canvasHeight - The height of the canvas.
   * @returns {Object} - Dimensions of the face mask.
   */
  function getFaceMaskDimensions(canvasWidth: number, canvasHeight: number) {
    const xCenter = canvasWidth / 2;

    const ratio = 6 / 7;
    const hFace = canvasHeight / 1.7;

    const wFace = hFace * ratio;
    return { xCenter, hFace, wFace };
  }

  /**
   * Function to get mask document rectangle
   * @param {number} xFace - The x-coordinate of the face.
   * @param {number} yFace - The y-coordinate of the face.
   * @param {number} wFace - The width of the face.
   * @returns {Object} - Rectangle for masking the document.
   */
  function getMaskDocumentRect(xFace: number, yFace: number, wFace: number) {
    const cardDimensions = {
      x: 85.6,
      y: 53.98,
    };

    const pRatio = cardDimensions.x / cardDimensions.y;

    //kurz links neben der gesichtsmaske unterkante Augenhöhe = auf der mittelpunkt der Gesichtsmaske
    const right = xFace - (wFace / 2 + 20);
    const left = 20;
    // rechts oben unterkante Augenhöhe auf bildschirm links
    const bottom = yFace;
    const pWidth = right - left;
    const pHeight = pWidth / pRatio;
    const top = bottom - pHeight;

    //immer mindestens 20px vom oberen rand entfernt
    const overflow = top < 20 ? 20 - top : 0;

    return { left, right, bottom: bottom + overflow, top: top + overflow };
  }

  /**
   * Function to get mask rectangle for photo of document
   * @param {Object} outer - Outer dimensions.
   * @param {Object} target - Target dimensions.
   * @returns {Object} - Mask rectangle for document photo.
   */
  function getMaskRectForPhotoOfDocument(
    outer: {
      x: number;
      y: number;
      w: number;
      h: number;
      scaleX: number;
      scaleY: number;
    },
    target: {
      x: number;
      y: number;
      w: number;
      h: number;
    }
  ): { x: number; y: number; w: number; h: number } {
    const scaleX = outer.scaleX;
    const scaleY = outer.scaleX;

    const rectWidth = target.w * scaleX;
    const rectHeight = target.h * scaleY;
    const x = outer.x + target.x;
    const y = target.h + target.y * scaleY;

    return { x: x, y: y, w: rectWidth, h: rectHeight };
  }

  /**
   * Function to draw with document mask
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @param {HTMLVideoElement} video - The video element.
   * @param {HTMLCanvasElement} canvas - The canvas element.
   */
  function drawWithDocumentMask(
    ctx: CanvasRenderingContext2D,
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement
  ) {
    //Blurred Background
    ctx.filter = "blur(20px) opacity(35%)";
    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);
    ctx.drawImage(video, 0, 0);
    ctx.beginPath();

    //MASKS
    const padding = 20;
    const cardIdRect = getMaskRectForDocumentFullSize(
      canvas.width,
      canvas.height - padding,
      padding
    );

    ctx.rect(cardIdRect.x, cardIdRect.y, cardIdRect.w, cardIdRect.h);

    const cardIdPhotoRect = getMaskRectForPhotoOfDocument(
      {
        x: cardIdRect.x,
        y: cardIdRect.y,
        w: cardIdRect.w,
        h: cardIdRect.h,
        scaleX: cardIdRect.scaleX,
        scaleY: cardIdRect.scaleY,
      },
      getDocumentPhotoRectByType(metaData!.document_type)
    );

    if (currentRect === undefined) {
      currentRect = {
        x: cardIdPhotoRect.x,
        y: cardIdPhotoRect.y,
        width: cardIdPhotoRect.w,
        height: cardIdPhotoRect.h,
      };
      dispatch(setDocumentCaptureRect(currentRect));
    }

    ctx.lineWidth = 3;
    ctx.rect(cardIdPhotoRect.x, cardIdPhotoRect.y, cardIdPhotoRect.w, cardIdPhotoRect.h);
    ctx.clip();

    //CLEAR Image
    ctx.filter = "none";

    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);
    ctx.drawImage(video, 0, 0);
    ctx.strokeStyle = process.env.REACT_PRIMARY_COLOR ?? "#6db4bb";
    ctx.lineWidth = 3;
    ctx.stroke();
  }

  /**
   * Calculates the mask rectangle for a full-size document capture.
   *
   * @param {number} width - The width of the canvas.
   * @param {number} height - The height of the canvas.
   * @param {number} padding - The padding around the document.
   * @returns {Object} - The mask rectangle for the full-size document capture.
   */
  function getMaskRectForDocumentFullSize(
    width: number,
    height: number,
    padding: number
  ): {
    x: number;
    y: number;
    w: number;
    h: number;
    scaleX: number;
    scaleY: number;
  } {
    const ratio = cardIdDimensions.x / cardIdDimensions.y;

    let rectWidth: number;
    let rectHeight: number;

    if (width / height > ratio) {
      rectHeight = height - 2 * padding;
      rectWidth = rectHeight * ratio;
    } else {
      rectWidth = width - 2 * padding;
      rectHeight = rectWidth / ratio;
    }

    const x = (width - rectWidth) / 2 + padding;
    const y = (height - rectHeight) / 2 + padding;

    return {
      x: x,
      y: y,
      w: rectWidth,
      h: rectHeight,
      scaleX: rectWidth / cardIdDimensions.x,
      scaleY: rectHeight / cardIdDimensions.y,
    };
  }

  /**
   * Stops the video playback.
   */
  function stopVideo() {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    video.pause();
    video.srcObject = null;
  }

  /**
   * Stops the camera stream.
   */
  function stopCameraStream() {
    for (const track of cameraStream?.getTracks() ?? []) {
      track.stop();
    }

    setCameraStream(null);
  }

  /**
   * Stops all video-related processes.
   */
  function stopVideoAndCameraStream() {
    try {
      stopVideo();
      stopCameraStream();
    } catch {
      loggerService.addLog(
        `${componentName} - stopVideoAndCameraStream: Camera turned off with error`,
        LogLevelType.Error
      );
    }
  }

  /**
   * Requests access to the camera stream.
   */
  function requestCameraStream() {
    if (cameraStream) {
      return;
    }

    const containerWidth =
      canvasRef.current?.parentElement?.getBoundingClientRect().width ?? 0;
    if (containerWidth === 0) {
      return;
    }

    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: "user",
          width: containerWidth - 20,
          height: containerWidth / 2,
        },
      })
      .then(
        (stream) => {
          setCameraStream(stream);
        },
        () =>
          enqueueSnackbar(t("events.authenticationCameraError", { ns: "snackbars" }), {
            variant: "error",
          })
      );
  }

  /**
   * Handles proceeding to the next step based on capture status.
   */
  function handleProceed() {
    if (captures.length === 1 && documentCaptures.length === 1) {
      routeToNextStep();
      return;
    }

    if (documentCaptures.length === 0 && captures.length === 1) {
      setModeCardIDCapture(true);
      return;
    }

    routeBack();
  }

  /**
   * Handles image capture from the canvas.
   */
  const handleCapture = () => {
    const capture = canvasRef.current?.toDataURL();

    if (capture) {
      if (modeCardIDCapture) {
        dispatch(addDocumentCapture(capture));
      } else {
        dispatch(addParticipantCapture(capture));
      }
    }

    setTimerStarted(false);
  };

  /**
   * Determines whether the user can proceed to the next step.
   */
  const canProcced =
    (captures.length === 1 && modeCardIDCapture === false) ||
    (documentCaptures.length === 1 && modeCardIDCapture);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CaptureHints modeCardIDCapture={modeCardIDCapture} />
      </Grid>
      <Grid item xs={12} md={10}>
        <canvas ref={canvasRef} />
      </Grid>
      <Grid item xs={12} md={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={12}>
            <CustomButton
              id="camera-on"
              onClick={
                cameraStream === null ? requestCameraStream : stopVideoAndCameraStream
              }
              startIcon={<PlayArrow />}
              color={cameraStream === null ? "primary" : "secondary"}
            >
              {cameraStream === null
                ? t("shotCommands.cameraOn", {
                    ns: "authCapture",
                  })
                : t("shotCommands.cameraOff", {
                    ns: "authCapture",
                  })}
            </CustomButton>
          </Grid>
          {cameraStream !== null && !timerStarted && (
            <Grid item xs={6} md={12}>
              <CustomButton
                id="start-shot"
                onClick={() => setTimerStarted(true)}
                startIcon={<Camera />}
                variant="contained"
                color="primary"
              >
                {t("shotCommands.startShot", {
                  ns: "authCapture",
                })}
              </CustomButton>
            </Grid>
          )}
          <Grid item xs={12} alignContent="center" style={{ marginBottom: 12 }}>
            <Box margin="auto">
              {timerStarted ? (
                <CountDown
                  seconds={contdownToCapture()}
                  onZero={handleCapture}
                  color="error"
                  variant="h2"
                  align="center"
                />
              ) : null}
              {cameraStream
                ? timerStarted || (
                    <span>
                      {t("shotCommands.countdown", {
                        countdown: contdownToCapture(),
                        ns: "authCapture",
                      })}
                    </span>
                  )
                : null}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} md={12}>
          <Typography>
            {" "}
            {t("shotCommands.example", {
              ns: "authCapture",
            })}
          </Typography>
          <img
            src={modeCardIDCapture ? example2 : example1}
            alt="Beispiel"
            style={{ maxWidth: "100%", position: "relative" }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CaptureList isEditable={modeCardIDCapture === false} />
      </Grid>
      {modeCardIDCapture ? (
        <Grid item xs={12}>
          <CaptureIdCardList />
        </Grid>
      ) : null}
      {/* eslint-disable react/jsx-no-bind */}
      <NavControls canProceed={canProcced} onProceed={handleProceed} />
      <OnSiteAuthPopup />
    </Grid>
  );
}
